import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import GlobalsCss from "./Globals.css";
import NotFound from "./not-found";
import { ThemeProvider } from "./providers/ThemeProvider";
import Welcome from "./screens/Welcome";
import End from "./screens/survey/End";
import Questions from "./screens/survey/Questions";
import StartScreen from "./screens/survey/Start";
import PrivateRoute from "./components/PrivateRoute";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: (
      <ThemeProvider>
        <GlobalsCss />
        <NotFound />
      </ThemeProvider>
    ),
    children: [
      {
        path: "/",
        element: <Welcome />,
      },
      {
        path: "/:encodedSurveyData",
        element: <Welcome />,
      },
      {
        path: "/survey",
        element: (
          <PrivateRoute>
            <StartScreen />
          </PrivateRoute>
        ),
        errorElement: <NotFound />,
      },
      {
        path: "/end",
        element: (
          <PrivateRoute>
            <End />
          </PrivateRoute>
        ),
        errorElement: <NotFound />,
      },
      {
        path: "/questions",
        element: (
          <PrivateRoute>
            <Questions />
          </PrivateRoute>
        ),
        errorElement: <NotFound />,
      },
    ],
  },
]);

export default router;
