import { ISizes } from "./types";
import { rem } from "./utils";

export const borderRadiusSizes: ISizes = {
  xxxsmall: rem(0.125),
  xxsmall: rem(0.25),
  xsmall: rem(0.5),
  small: rem(0.75),
  medium: rem(1),
  large: rem(1.5),
  xlarge: rem(2),
  xxlarge: rem(3),
  xxxlarge: rem(4),
};

export const spacingSizes: ISizes = {
  xxxsmall: rem(0.125),
  xxsmall: rem(0.25),
  xsmall: rem(0.5),
  small: rem(0.75),
  medium: rem(1.125),
  large: rem(1.5),
  xlarge: rem(2),
  xxlarge: rem(3),
  xxxlarge: rem(4),
};

export const typographySizes: ISizes = {
  xxxsmall: rem(0.125),
  xxsmall: rem(0.25),
  xsmall: rem(0.5),
  small: rem(0.75),
  medium: rem(1),
  large: rem(1.5),
  xlarge: rem(2.5),
  xxlarge: rem(3),
  xxxlarge: rem(5),
};
