import React from "react";
import { useSearchParams } from "react-router-dom";
import { styled } from "styled-components";
import Header from "../../components/Header";
import { useSurveyProgress } from "../../providers/SurveyProgressProvider";
import { useCustomTheme } from "../../providers/ThemeProvider";
import {
  Box,
  Footer,
  Icon,
  InfoBox,
  Screen,
  Spacer,
  Text,
} from "../../ui/primitives";
import Spacing, { SpacingMixinProps } from "../../ui/theme/mixins/Spacing";
import { surveyTime } from "../../utils/calculateSurveyLength";
import { PRIVACY_TYPE_COPY } from "../../utils/consts";
import { saveToSessionStorage } from "../../utils/storageSave";

interface StartScreenProps { }

const StartScreen = (props: StartScreenProps) => {
  const { progress } = useSurveyProgress();
  const { updateBackground } = useCustomTheme();

  React.useEffect(() => {
    updateBackground("sky");
  }, []);

  if (!progress) return null;

  const questions = progress.categories.map((c) => c.questions).flat();
  const numberOfQuestions = questions.length;

  const { min, max } = surveyTime(numberOfQuestions);

  const uiElements = progress.meta.uiElements;

  const welcomeSubject = uiElements.find(
    (u) => u.key === "welcomeSubject"
  )?.value;
  const welcomeBody = uiElements.find((u) => u.key === "welcomeBody")?.value;

  return (
    <Container
      showDandi
      bgColor="bg.C"
      header={<Header />}
      footer={
        <Footer ctaText="Let's get started" ctaHref="/questions">
          <Icon
            name="InfoShield"
            size={20}
            marginRight="small"
            marginTop="xxsmall"
          ></Icon>
          <CopyContainer>
            <Text.Copy marginBottom="medium">
              {PRIVACY_TYPE_COPY[progress.privacy.type]}
            </Text.Copy>

            <Text.CopySmall>
              By continuing, you agree to Dandi's{" "}
              <Link
                href="https://itsdandi.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                <Text.CopySmall>terms</Text.CopySmall>
              </Link>{" "}
              and{" "}
              <Link
                href="https://itsdandi.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                <Text.CopySmall>privacy policy</Text.CopySmall>
              </Link>
              .
            </Text.CopySmall>
            <Link
              href="https://itsdandi.com/data-privacy"
              target="_blank"
              rel="noreferrer"
            >
              <Text.CopySmall>Learn more about Dandi privacy</Text.CopySmall>
            </Link>
          </CopyContainer>
        </Footer>
      }
    >
      <WelcomeContainer bgColor="bg.B">
        <Text.Copy>Self ID</Text.Copy>
        <Spacer size="medium" />
        <InfoBoxContainer marginBottom={welcomeSubject ? "xlarge" : 0}>
          <InfoBox label="Questions">
            <h2>{numberOfQuestions}</h2>
          </InfoBox>
          <InfoBox label="Estimated Time">
            <h2>
              {min}-{max} min
            </h2>
          </InfoBox>
        </InfoBoxContainer>
        {welcomeSubject && <Spacer size="large" />}
        {welcomeSubject && <Text.H2>{welcomeSubject}</Text.H2>}
        {(welcomeSubject || welcomeBody) && <Spacer size="large" />}
        {welcomeBody && (
          <Text.Copy style={{ opacity: 0.75 }}>{welcomeBody}</Text.Copy>
        )}
      </WelcomeContainer>
    </Container>
  );
};

const Container = styled(Screen)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (min-width: 768px) {
    padding-top: 30svh;
    padding-bottom: 10svh;
  }

  overflow: auto;
`;

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  overflow: visible;
`;

const InfoBoxContainer = styled.div<SpacingMixinProps>`
  display: flex;
  justify-content: stretch;
  /* flex: 1; */
  width: 100%;
  column-gap: ${({ theme }) => theme.spacing.sizes.small}px;

  ${Spacing};
`;

const Link = styled.a`
  text-decoration: underline;
  color: inherit;
`;

const WelcomeContainer = styled(Box)`
@media screen and (max-height: 800px) {
  margin-top: -70px;
}
@media screen and (max-height: 700px) {
  margin-top: -120px;
}
`

export default StartScreen;
