import { Screen } from "../ui/primitives";
import Load from "../components/Load";
import { useSurveyProgress } from "../providers/SurveyProgressProvider";
import NotFound from "../not-found";

export default function PrivateRoute({ children }: any) {
  const { progress, loading, loaded } = useSurveyProgress();

  const isAuthenticated = !!progress;

  if (loading || !loaded) {
    return (
      <Screen bgColor="bg.C" showDandi={false}>
        <Load wink />
      </Screen>
    );
  }
  return isAuthenticated ? children : <NotFound />;
}
