import styled from "styled-components";
import { Icon, Spacer, Text } from "../ui/primitives";

interface DandiInfoProps { }

const DandiInfo = (props: DandiInfoProps) => {
  return (
    <Container>
      <Link href="https://itsdandi.com" target="_blank" rel="noreferrer">
        <Icon name="DandiVertical" height={71} width={21} />
      </Link>
      <Spacer size="medium" />
      <Text.CopySmall style={{ opacity: 0.5 }}>
        Dandi Technologies Inc.
      </Text.CopySmall>
      <Text.CopySmall style={{ opacity: 0.5 }}>
        <Link
          target="_blank"
          rel="noreferrer"
          href="https://itsdandi.com/terms"
        >
          Terms
        </Link>
        {" & "}
        <Link
          target="_blank"
          rel="noreferrer"
          href="https://itsdandi.com/privacy"
        >
          Privacy
        </Link>
      </Text.CopySmall>
    </Container>
  );
};

const Link = styled.a`
  text-decoration: underline;
  color: inherit;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;
`;
export default DandiInfo;
