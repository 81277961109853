import { IconProps } from "../types";

interface DandiVerticalProps extends IconProps {}

const DandiVertical = ({ size = 71 }: DandiVerticalProps) => {
  const width = (size / 21) * size;
  const height = (size / 71) * size;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 21 71'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.4564 20.3906C10.4564 20.3906 14.4445 20.8867 14.6272 25.8081C14.7578 29.3132 12.7605 31.6564 9.35987 31.6564H0.29372V28.7323H1.75579C1.75579 28.7323 0 27.1266 0 24.4896C0 21.5655 2.04951 19.516 4.09249 19.516C6.43572 19.516 8.12624 21.0433 8.47871 23.6085C8.75937 25.6776 9.07267 28.791 10.1758 28.791C11.5987 28.791 12.4276 27.8642 12.4276 25.9909C12.4276 25.9909 12.4472 23.5628 10.4499 23.0015V20.3906H10.4564ZM6.72944 28.7323H7.60407C7.6824 28.7323 7.72809 28.6409 7.6824 28.5821C6.97747 27.6618 6.63154 25.4818 6.29213 24.2025C5.99841 23.0928 5.4175 22.4467 4.39274 22.4467C3.51811 22.4467 2.63695 23.3213 2.63695 24.9335C2.63042 27.2702 4.09249 28.7323 6.72944 28.7323Z'
        fill='currentColor'
      />
      <path
        d='M12.6238 37.7331C12.5324 37.7331 12.4932 37.8506 12.565 37.9093C13.1786 38.3466 14.6211 39.626 14.6211 41.9757C14.6211 45.3372 12.2778 47.4846 8.47905 47.4846H0.294067V44.5604H8.48558C10.5351 44.5604 11.9972 43.4835 11.9972 41.5841C11.9972 39.391 10.5351 37.7331 7.46083 37.7331H0.294067V34.809H14.3339V37.7331H12.6238Z'
        fill='currentColor'
      />
      <path
        d='M11.9968 61.0288H20.7692V63.953H0.29372V61.0288H2.29754C2.39545 61.0288 2.42808 60.9048 2.34976 60.8526C1.67094 60.3892 0 58.9924 0 56.4925C0 52.8373 3.21786 49.8087 7.31036 49.8087C11.4028 49.8087 14.6207 52.8308 14.6207 56.4925C14.6207 59.26 12.8258 60.4348 11.9511 60.8395C11.8597 60.8852 11.8924 61.0288 11.9968 61.0288ZM7.31036 61.0288C9.94078 61.0288 11.9903 58.9793 11.9903 56.7862C11.9903 54.5931 9.94078 52.7394 7.31036 52.7394C4.67993 52.7394 2.63042 54.5931 2.63042 56.7862C2.63042 58.9793 4.67993 61.0288 7.31036 61.0288Z'
        fill='currentColor'
      />
      <path
        d='M0.294067 70.7149V67.7908H14.3339V70.7149H0.294067ZM16.6249 70.7149V67.7908H19.5751V70.7149H16.6249Z'
        fill='currentColor'
      />
      <path
        d='M20.7696 7.16694V5.33936H17.8454V7.16694C17.8454 11.1158 14.6276 14.3337 10.5351 14.3337C6.4426 14.3337 3.22474 11.1158 3.22474 7.16694V5.33936H0.294067V7.16694C0.294067 12.8716 4.974 17.5516 10.5351 17.5516C16.0897 17.5516 20.7696 12.8716 20.7696 7.16694Z'
        fill='currentColor'
      />
      <path d='M16.2336 0H13.0483V3.21786H16.2336V0Z' fill='currentColor' />
      <path d='M8.19841 0H5.01318V3.21786H8.19841V0Z' fill='currentColor' />
    </svg>
  );
};

export default DandiVertical;
