import { DefaultTheme } from "styled-components";

import * as colors from "./colors";
import { palette } from "./palettes";
import * as sizes from "./sizing";

export const defaultTheme: DefaultTheme = {
  name: "default-light",
  type: "light",

  colors: colors,
  palette: palette.light,

  shape: {
    borderRadius: sizes.borderRadiusSizes,
  },

  typography: {
    families: {
      sansSerif: "dandi-grotesk",
      monospace: "jetbrains",
    },
    sizes: sizes.typographySizes,
  },

  spacing: {
    sizes: sizes.spacingSizes,
    contentMaxWidth: 720,
  },
};
