import styled from "styled-components";
import { IconProps } from "../types";

interface HamburgerProps extends IconProps {}

const Hamburger = ({ size = 24 }: HamburgerProps) => {
  const width = (size / 19) * size;
  const height = (size / 16) * size;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 19 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='11' height='2' fill='currentColor' fill-opacity='0.75' />
      <rect
        y='7'
        width='19'
        height='2'
        fill='currentColor'
        fillOpacity='0.75'
      />
      <rect
        y='14'
        width='14'
        height='2'
        fill='currentColor'
        fillOpacity='0.75'
      />
    </svg>
  );
};

const Container = styled.div``;
export default Hamburger;
