import React, { Dispatch, SetStateAction } from "react";
import {
  DefaultTheme,
  ThemeProvider as DefaultThemeProvider,
} from "styled-components";

import { ColorName, PreferredThemeType } from "../ui/theme/types";
import DefaultDarkTheme from "../ui/theme/versions/DefaultDarkTheme";
import DefaultLightTheme from "../ui/theme/versions/DefaultLightTheme";
import {
  PREFERRED_DARK_THEME,
  PREFERRED_LIGHT_THEME,
  PREFERRED_THEME_VERSION,
} from "../utils/consts";
import { load, save } from "../utils/storage";
import { switchBackground, themeBuilder } from "./utils";

interface IThemeContext {
  theme: DefaultTheme;
  setTheme: Dispatch<SetStateAction<DefaultTheme>>;
  preferredLight: DefaultTheme;
  setPreferredLight: Dispatch<SetStateAction<DefaultTheme>>;
  preferredDark: DefaultTheme;
  setPreferredDark: Dispatch<SetStateAction<DefaultTheme>>;
  preferredTheme: PreferredThemeType;
  setPreferredTheme: Dispatch<SetStateAction<PreferredThemeType>>;
  themeLoaded: boolean;
  updateBackground: (name?: ColorName) => void;
}

const initialContext: IThemeContext = {
  theme: DefaultLightTheme,
  setTheme: () => {},
  preferredLight: DefaultLightTheme,
  setPreferredLight: () => {},
  preferredDark: DefaultDarkTheme,
  setPreferredDark: () => {},
  preferredTheme: DefaultLightTheme.type,
  setPreferredTheme: () => {},
  themeLoaded: false,
  updateBackground: () => {},
};

const ThemeContext = React.createContext<IThemeContext>(initialContext);

interface IThemeProvider {
  children?: React.ReactNode;
}

const ThemeProvider = ({ children }: IThemeProvider) => {
  const [theme, setTheme] = React.useState(DefaultLightTheme);
  const [preferredLight, setPreferredLight] = React.useState(DefaultLightTheme);
  const [preferredDark, setPreferredDark] = React.useState(DefaultDarkTheme);
  const [prefersDark, setPrefersDark] = React.useState(false);
  const [currentBackgroundColor, setCurrentBackgroundColor] =
    React.useState<ColorName>("sky");

  const [themeLoaded, setThemeLoaded] = React.useState(false);

  const supportedColors: ColorName[] = [
    "cherry",
    "sky",
    "purple",
    "coral",
    "yellow",
    "green",
    "candy",
    "mint",
  ];

  const [preferredTheme, setPreferredTheme] =
    React.useState<PreferredThemeType>("system");

  // const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const updateBackground = (name?: ColorName) => {
    if (!name) {
      let randomBackground: ColorName;
      do {
        randomBackground =
          supportedColors[Math.floor(Math.random() * supportedColors.length)];
      } while (randomBackground === currentBackgroundColor);

      switchBackground(theme, randomBackground);
      setCurrentBackgroundColor(randomBackground);
    } else {
      switchBackground(theme, name);
      setCurrentBackgroundColor(name);
    }
  };

  const handleSaveLight = async () => {
    if (!preferredLight || !themeLoaded) return;
    await save(PREFERRED_LIGHT_THEME, preferredLight.name);
  };
  const handleSaveDark = async () => {
    if (!preferredDark || !themeLoaded) return;
    await save(PREFERRED_DARK_THEME, preferredDark.name);
  };

  const handleSavePreferred = async () => {
    if (!preferredTheme || !themeLoaded) return;
    await save(PREFERRED_THEME_VERSION, preferredTheme);
  };

  const handleLoad = async () => {
    const storageLightThemeName = await load(PREFERRED_LIGHT_THEME);
    const storageDarkThemeName = await load(PREFERRED_DARK_THEME);
    const storagePreferredTheme = await load(PREFERRED_THEME_VERSION);

    setPreferredTheme(storagePreferredTheme || "system");
    if (storageLightThemeName) {
      setPreferredLight(themeBuilder(storageLightThemeName));
    }
    if (storageDarkThemeName) {
      setPreferredDark(themeBuilder(storageDarkThemeName));
    }

    setThemeLoaded(true);
  };

  React.useEffect(() => {
    if (!themeLoaded) return;

    if (preferredTheme === "system") {
      if (!prefersDark) {
        setTheme(preferredLight);
      } else {
        setTheme(preferredDark);
      }
    }
  }, [prefersDark, themeLoaded, preferredDark, preferredLight]);

  React.useEffect(() => {
    if (!window || typeof window === "undefined" || !window.matchMedia) return;
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", ({ matches }) => {
        if (matches) {
          setPrefersDark(true);
        } else {
          setPrefersDark(false);
        }
      });

    return () =>
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", ({ matches }) => {});
  }, []);

  React.useEffect(() => {
    if (preferredTheme === "dark") {
      setTheme(preferredDark);
    }
    handleSaveLight();
  }, [preferredDark]);

  React.useEffect(() => {
    if (preferredTheme === "light") {
      setTheme(preferredLight);
    }
    handleSaveDark();
  }, [preferredLight]);

  React.useEffect(() => {
    if (preferredTheme === "light") {
      setTheme(preferredLight);
    }
    if (preferredTheme === "dark") {
      setTheme(preferredDark);
    }
    if (preferredTheme === "system") {
      if (prefersDark) {
        setTheme(preferredDark);
      }
      if (!prefersDark) {
        setTheme(preferredLight);
      }
    }
    handleSavePreferred();
  }, [preferredTheme]);

  React.useEffect(() => {
    handleLoad();
  }, []);

  if (!themeLoaded) return null;

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        preferredLight,
        setPreferredLight,
        preferredDark,
        setPreferredDark,
        themeLoaded,
        preferredTheme,
        setPreferredTheme,
        updateBackground,
      }}
    >
      <DefaultThemeProvider theme={theme}>
        <>{children}</>
      </DefaultThemeProvider>
    </ThemeContext.Provider>
  );
};

const useCustomTheme = () => React.useContext(ThemeContext);

export { ThemeProvider, useCustomTheme };
