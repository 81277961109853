import { useTheme } from "styled-components";
import WinkBlack from "../assets/animations/Wink-Dark.json";
import WinkWhite from "../assets/animations/Wink.json";
import LottieAnimation from "../components/LottieAnimation";

interface WaitPageProps {
  wink: boolean;
}

export default function WaitPage({ wink }: WaitPageProps) {
  const theme = useTheme();

  const Wink = theme.type === "light" ? WinkBlack : WinkWhite;

  return (
    <div className='wait-page'>
      {wink ? (
        <LottieAnimation
          width={200}
          height={200}
          animation={Wink}
          autoplay={true}
        ></LottieAnimation>
      ) : (
        <LottieAnimation
          width={200}
          height={200}
          animation={Wink}
          autoplay={true}
        ></LottieAnimation>
      )}
    </div>
  );
}
