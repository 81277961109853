import React from "react";

export function useScreenSize(): { width: number; height: number } {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    if (!window || typeof window === "undefined" || !window.removeEventListener)
      return;
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      if (
        !window ||
        typeof window === "undefined" ||
        !window.removeEventListener
      )
        return;
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { width, height };
}
