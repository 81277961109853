import { Link } from "react-router-dom";
import { css, styled } from "styled-components";
import Button from "../Button";

interface FooterProps {
  children?: React.ReactNode;
  cta?: React.ReactNode;
  ctaText?: string;
  ctaHref?: string;
}

const Footer = ({ children, ctaText, ctaHref = "/" }: FooterProps) => {
  return (
    <Container>
      <ContentWrapper>
        <ChildrenContainer>{children}</ChildrenContainer>
        <Link to={ctaHref} tabIndex={-1}>
          <Button.Primary style={{ width: "100%" }}>{ctaText}</Button.Primary>
        </Link>
      </ContentWrapper>
    </Container>
  );
};

const ContentWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  width: 100%;
  max-width: 1000px;
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.sizes.xlarge}px;
    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: stretch;
      justify-content: stretch;
      padding: 0;
    }
  `}
`;

const ChildrenContainer = styled("div")`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  width: 100%;
`;

const Container = styled("div")`
  display: flex;
  justify-content: center;
  ${({ theme }) => css`
    background-color: ${theme.palette.bg.A};
    padding: ${theme.spacing.sizes.xxlarge}px;
    @media screen and (max-width: 768px) {
      padding: ${theme.spacing.sizes.large}px;
    }
    box-shadow: 0px -4px 100px ${theme.palette.bg.C};
  `}
`;

export default Footer;
