import { IColor, IGreyscale } from "./types";

export const yellow: IColor = {
  0: "#FFFFFF",
  50: "#FFFCF3",
  100: "#FFFBED",
  200: "#FFF0BC",
  300: "#FFE99A", // main light
  400: "#F0DB8E",
  500: "#B4A77B",
  600: "#7D7760",
  700: "#5B5438",
  800: "#463F27", // main dark
  900: "#2E2916",
  1000: "#1F1B0E",
};

export const green: IColor = {
  0: "#FFFFFF",
  50: "#FCFFFB",
  100: "#F1FFEA",
  200: "#D7FFC5",
  300: "#BCFF9C", // main light
  400: "#88D862",
  500: "#88A978",
  600: "#7A8F70",
  700: "#5D7055",
  800: "#374431", // main dark
  900: "#24321D",
  1000: "#121C0E",
};

export const purple: IColor = {
  0: "#FFFFFF",
  50: "#FEF8FF",
  100: "#FCECFF",
  200: "#FBDFFF",
  300: "#F9D2FF", // main light
  400: "#D88DE3",
  500: "#916F96",
  600: "#716373",
  700: "#5B4A5E",
  800: "#3E2E41", // main dark
  900: "#2E1D31",
  1000: "#1A0F1C",
};

export const sky: IColor = {
  0: "#FFFFFF",
  50: "#F2FEFF",
  100: "#DBFDFF",
  200: "#C0FCFF",
  300: "#A4F0F4", // main light
  400: "#7AD2D7",
  500: "#64A0A4",
  600: "#608486",
  700: "#446264",
  800: "#294647", // main dark
  900: "#14292B",
  1000: "#091616",
};

export const coral: IColor = {
  0: "#FFFFFF",
  50: "#FFF9F8",
  100: "#FFEBE4",
  200: "#FFCFBF",
  300: "#FFAD93", // main light
  400: "#E98D6F",
  500: "#A47060",
  600: "#7C655E",
  700: "#614942",
  800: "#402C26", // main dark
  900: "#251612",
  1000: "#110A07",
};

export const cherry: IColor = {
  0: "#FFFFFF",
  50: "#FFF0F2",
  100: "#FFE2E6",
  200: "#FFCBD1",
  300: "#FFB5BE", // main light
  400: "#E18590",
  500: "#9C6269",
  600: "#7B6063",
  700: "#64484B",
  800: "#482C2F", // main dark
  900: "#291618",
  1000: "#1A0B0D",
};

export const mint: IColor = {
  0: "#FFFFFF",
  50: "#F1FFFA",
  100: "#D0FFEE",
  200: "#B1FFE3",
  300: "#A5FEDE", // main light
  400: "#74E5BD",
  500: "#5A9680",
  600: "#557066",
  700: "#3D574E",
  800: "#263D34", // main dark
  900: "#142B23",
  1000: "#0B1A14",
};

export const candy: IColor = {
  0: "#FFFFFF",
  50: "#FFF4FB",
  100: "#FFE3F6",
  200: "#FFCFEF",
  300: "#FFB6E6", // main light
  400: "#DD7ABB",
  500: "#A96792",
  600: "#86647A",
  700: "#64495B",
  800: "#432A3A", // main dark
  900: "#351A2C",
  1000: "#1E0C18",
};

/** OLD */

export const skyBlue: IColor = {
  0: "#FFFFFF",
  50: "#A4F5FF",
  100: "#87F2FF",
  200: "#77ECFB",
  300: "#67E1F0",
  400: "#60D7E6",
  500: "#51CEDE",
  600: "#3AB5C5",
  700: "#269CAB",
  800: "#0F6671",
  900: "#05373E",
  1000: "#011D21",
};

export const springGreen: IColor = {
  0: "#FFFFFF",
  50: "#85FFC5",
  100: "#4EFFAB",
  200: "#2AFC98",
  300: "#24F08F",
  400: "#16E583",
  500: "#0FDD7B",
  600: "#0ACA6F",
  700: "#05B763",
  800: "#007E43",
  900: "#012F19",
  1000: "#001D0F",
};

export const gunmetal: IColor = {
  0: "#FFFFFF",
  50: "#AFE6FC",
  100: "#91C8DE",
  200: "#83BBD1",
  300: "#6DA5BC",
  400: "#5C95AC",
  500: "#4E8196",
  600: "#427083",
  700: "#355F70",
  800: "#254653",
  900: "#19323C",
  1000: "#0F2128",
};

export const razzmataz: IColor = {
  0: "#FFFFFF",
  50: "#FF7EA9",
  100: "#FC548C",
  200: "#F0437D",
  300: "#DB3069",
  400: "#D0245E",
  500: "#C61D55",
  600: "#B9174D",
  700: "#9F0F3F",
  800: "#8A0A35",
  900: "#6C0024",
  1000: "#430016",
};

export const mangoTango: IColor = {
  0: "#FFFFFF",
  50: "#FFB493",
  100: "#FF8E5B",
  200: "#FA824C",
  300: "#F17A44",
  400: "#EB7039",
  500: "#DE652E",
  600: "#D75B23",
  700: "#C14E1A",
  800: "#AF4212",
  900: "#8F3007",
  1000: "#561D04",
};

export const greyscale: IGreyscale = {
  white: "#FFFFFF",
  0: "#FFFFFF",
  50: "#EFFBFF",
  100: "#E3F4FB",
  200: "#D5ECF5",
  300: "#C7DBE2",
  400: "#ADC0C8",
  500: "#9AAFB7",
  600: "#728890",
  700: "#54676E",
  800: "#39484E",
  900: "#151B1E",
  1000: "#030607",
  black: "#000000",
};
