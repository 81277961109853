import { css, styled } from "styled-components";
import Spacing, { SpacingMixinProps } from "../../theme/mixins/Spacing";
import Text from "../Text";

interface InfoBoxProps extends SpacingMixinProps {
  label?: string | React.ReactNode;
  children?: React.ReactNode;
}

const InfoBox = ({ label, children, ...rest }: InfoBoxProps) => {
  return (
    <Container {...rest}>
      <Text.CopySmall style={{ opacity: 0.5 }}>{label}</Text.CopySmall>
      <span>{children}</span>
    </Container>
  );
};

const Container = styled.div<InfoBoxProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.palette.bg.B};
    border-radius: ${theme.shape.borderRadius.xsmall}px;
    border: 1px solid ${theme.palette.border.C + "80"};
    padding: ${theme.spacing.sizes.xxsmall}px ${theme.spacing.sizes.small}px;
  `};

  ${Spacing};
`;

export default InfoBox;
