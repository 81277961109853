export function saveToLocalStorage(key: string, value: string) {
  if (typeof window === "undefined") {
    return;
  }
  window.localStorage.setItem(key, value);
}

export function getFromLocalStorage(key: string): string | null {
  if (typeof window === "undefined") {
    return null;
  }
  return window.localStorage.getItem(key);
}

export function removeFromLocalStorage(key: string) {
  window.localStorage.removeItem(key);
}

export function saveToSessionStorage(key: string, value: string) {
  if (typeof window === "undefined") {
    return;
  }
  window.sessionStorage.setItem(key, value);
}

export function getFromSessionStorage(key: string): string | null {
  if (typeof window === "undefined") {
    return null;
  }
  return window.sessionStorage.getItem(key);
}

export function removeFromSessionStorage(key: string) {
  window.sessionStorage.removeItem(key);
}
