import React from "react";
import { css, styled } from "styled-components";
import ImageContainer from "../../components/ImageContainer";
import { useSurveyProgress } from "../../providers/SurveyProgressProvider";
import { useCustomTheme } from "../../providers/ThemeProvider";
import { Box, Screen, Text } from "../../ui/primitives";

interface EndProps { }

const End = (props: EndProps) => {
  const { updateBackground } = useCustomTheme();
  const { progress } = useSurveyProgress();

  React.useEffect(() => {
    updateBackground("sky");
  }, []);

  if (!progress) return null;

  const uiElements = progress?.meta.uiElements || [];
  const thankYouSubject = uiElements.find(
    (u) => u.key === "thankYouSubject"
  )?.value;
  const thankYouBody = uiElements.find((u) => u.key === "thankYouBody")?.value;
  const thankImage =
    "https://firebasestorage.googleapis.com/v0/b/dandi-184419.appspot.com/o/survey-thank-images%2F083ce871-8ccf-4983-b05c-7568080eb405?alt=media&token=605aa7c9-5f9e-4164-be69-91118bb0a204";

  return (
    <Container bgColor="bg.C">
      <InnerBox bgColor="bg.B">
        {thankImage && (
          <ImageContainer
            imageUrl={thankImage}
            width={180}
            height={180}
          ></ImageContainer>
        )}
        {thankYouSubject && (
          <Text.H3 weight="regular" align="center">
            {thankYouSubject}
          </Text.H3>
        )}
        {thankYouBody && <Text.Copy align="center">{thankYouBody}</Text.Copy>}
      </InnerBox>
    </Container>
  );
};

const Container = styled(Screen)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InnerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sizes.medium}px;
  ${({ theme }) => css`
    padding: ${theme.spacing.sizes.xxlarge}px;
  `}

  max-width: 40rem;
`;

export default End;
