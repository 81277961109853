import styled, { useTheme } from "styled-components";
import { SpacingMixinProps } from "../../theme/mixins/Spacing";
import { SizeType } from "../../theme/types";
import { getSpacingInPixels } from "../../theme/utils";

interface SpacerProps extends SpacingMixinProps {
  size?: SizeType;
}

const Spacer = ({ size = "medium", ...rest }: SpacerProps) => {
  const theme = useTheme();
  const sizeValue = getSpacingInPixels(size, theme);

  return <Container {...rest} style={{ height: sizeValue }}></Container>;
};

const Container = styled.div<SpacerProps>``;
export default Spacer;
