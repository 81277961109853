import React from "react";

interface IKeypress {
  key: string;
  timestamp: number;
}

interface IKeypressProvider {
  keypress?: IKeypress;
}

const initialContext: IKeypressProvider = {
  keypress: undefined,
};

const KeypressContext = React.createContext<IKeypressProvider>(initialContext);

interface KeypressProviderProps {
  children: React.ReactNode;
}

const KeypressProvider = ({ children }: KeypressProviderProps) => {
  const [keypress, setKeypress] = React.useState<IKeypress>();

  const updateKeypress = (newKeypress: string) => {
    setKeypress({ key: newKeypress, timestamp: Date.now() });
  };

  // React.useEffect(() => {
  //   window.addEventListener("keydown", (e) => {
  //     if (e.key === "Tab") {
  //       //e.preventDefault();
  //     }
  //     updateKeypress(e.key);
  //   });
  // }, []);

  return (
    <KeypressContext.Provider value={{ keypress }}>
      {children}
    </KeypressContext.Provider>
  );
};

const useKeypress = () => React.useContext(KeypressContext);

export { KeypressProvider, useKeypress };
