import { css, styled } from "styled-components";
import { useSmallScreen } from "../../hooks/useSmallScreen";
import { useSurveyProgress } from "../../providers/SurveyProgressProvider";
import { IQuestion } from "../../types";
import { Button, Icon, Spacer, Text } from "../../ui/primitives";
import { PRIVACY_TYPE_COPY } from "../../utils/consts";

function getProgress(questions: IQuestion[]) {
  const numberOfQuestionsAnswered = questions.filter((q) =>
    q.values.some((v) => v.score > 0)
  ).length;

  return Math.round((numberOfQuestionsAnswered / questions.length) * 100);
}

interface SidebarProps {
  children?: React.ReactNode;
  setCurrentQuestion: (question: number) => void;
  currentQuestion: number;
  questions: IQuestion[];
  surveyName?: string;
  closeSidebar?: () => void;
  submitSurvey?: () => void;
}

const Sidebar = ({
  currentQuestion,
  setCurrentQuestion,
  questions,
  surveyName,
  closeSidebar,
  submitSurvey,
}: SidebarProps) => {
  const isSmallScreen = useSmallScreen();
  const { progress } = useSurveyProgress();

  if (!progress) return null;

  return (
    <Container>
      <TitleContainer>
        {isSmallScreen ? (
          <HeaderContainer>
            <HeaderTitleContainer>
              <Text.CopySmall>Survey Overview</Text.CopySmall>
            </HeaderTitleContainer>
            <Icon
              name="X"
              onClick={closeSidebar}
              size={24}
              marginRight="large"
            ></Icon>
          </HeaderContainer>
        ) : (
          <>
            <Text.Copy size="medium">{surveyName}</Text.Copy>
            <Spacer size="small" />
          </>
        )}
        {!isSmallScreen && (
          <Divider>
            <Bar width={getProgress(questions)}></Bar>
          </Divider>
        )}
      </TitleContainer>
      <QuestionsContainer>
        {questions.map((question, index) => {
          const answered = question.values.some((v) => v.score > 0);
          return (
            <QuestionNameContainer
              active={index === currentQuestion}
              onClick={() => {
                setCurrentQuestion(index);
                closeSidebar && closeSidebar();
              }}
            >
              <QuestionName>
                {index + 1} — {question.fieldName}
              </QuestionName>
              {answered && (
                <Icon
                  name="Checkbox"
                  size={18}
                  marginRight={isSmallScreen ? "medium" : "xlarge"}
                ></Icon>
              )}
            </QuestionNameContainer>
          );
        })}
        <Spacer size="large" />
        <Button.Primary
          marginLeft="xxlarge"
          marginRight="xlarge"
          onClick={submitSurvey}
        >
          Let's wrap it up
        </Button.Primary>
        <Spacer size="xxlarge" />
      </QuestionsContainer>
      {!isSmallScreen && (
        <Footer>
          <Icon name="InfoShield" size={20} marginTop="xxsmall" />
          <CopyContainer>
            <Text.Copy marginBottom="medium">
              {!!progress && PRIVACY_TYPE_COPY[progress.privacy.type]}
            </Text.Copy>
            <Link
              href="https://itsdandi.com/data-privacy"
              target="_blank"
              rel="noreferrer"
            >
              <Text.CopySmall>Learn more about Dandi privacy</Text.CopySmall>
            </Link>
          </CopyContainer>
        </Footer>
      )}
    </Container>
  );
};

const Link = styled.a`
  text-decoration: underline;
  color: inherit;
`;

const QuestionName = styled(Text.Copy)`
  font-size: 0.875rem;
`;

const Container = styled.div`
  transition: background-color 1s ease-in-out;
  z-index: 998;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  max-width: 400px;
  ${({ theme }) => css`
    background-color: ${theme.palette.bg.B};
    padding-top: ${theme.spacing.sizes.xxlarge}px;

    @media screen and (max-width: 768px) {
      padding-top: ${theme.spacing.sizes.medium}px;
      max-width: unset;
    }
  `};
`;

const TitleContainer = styled.div`
  ${({ theme }) => css`
    padding-left: ${theme.spacing.sizes.small}px;
    margin-left: ${theme.spacing.sizes.xxlarge}px;

    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  `}
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  ${({ theme }) => css`
    background-color: ${theme.palette.bg.A};
  `}
`;

interface BarProps {
  width?: number;
}
const Bar = styled.div<BarProps>`
  width: ${({ width }) => width || 0}%;
  height: 1px;
  transition: width 0.5s ease-in-out;
  ${({ theme }) => css`
    background-color: ${theme.palette.border.A};
  `}
`;

const QuestionsContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  gap: 8px;

  ${({ theme }) => css`
    padding-top: ${theme.spacing.sizes.large}px;
  `}
`;

interface QuestionNameContainerProps {
  active: boolean;
}

const QuestionNameContainer = styled.div<QuestionNameContainerProps>`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme, active }) => css`
    border-top: ${active
      ? "1px solid" + theme.palette.border.A
      : "1px solid transparent"};
    border-left: ${active
      ? "1px solid" + theme.palette.border.A
      : "1px solid transparent"};
    border-bottom: ${active
      ? "1px solid" + theme.palette.border.A
      : "1px solid transparent"};
    padding: ${theme.spacing.sizes.small}px;
    border-radius: ${theme.shape.borderRadius.xsmall}px 0 0
      ${theme.shape.borderRadius.xsmall}px;
    margin-left: ${theme.spacing.sizes.xxlarge}px;
    max-height: 40px;
    @media screen and (max-width: 768px) {
      margin-left: ${theme.spacing.sizes.small}px;
    }
    font-weight: ${active ? 600 : 400};
  `};
`;

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  position: relative;
  bottom: 0;
  right: 0;
  display: flex;
  flex-shrink: 1;
  z-index: 999;

  max-width: 400px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  ${({ theme }) => css`
    background-color: ${theme.palette.bg.A};
    padding: ${theme.spacing.sizes.large}px ${theme.spacing.sizes.xxlarge}px;

    gap: ${theme.spacing.sizes.small}px;
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export default Sidebar;
