import { css } from "styled-components";

import { FontFamily, SizeType, ThemePath } from "../types";
import { getColorString, getTypeSizeInPixels } from "../utils";

export type FontWeight =
  | "extralight"
  | "light"
  | "thin"
  | "regular"
  | "medium"
  | "semibold"
  | "bold"
  | "extrabold"
  | "black"
  | "display"
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900;

export interface TypographyMixinProps {
  color?: ThemePath;
  italic?: boolean;
  family?: FontFamily;
  weight?: FontWeight;
  uppercase?: boolean;
  size?: SizeType | number;
  align?: "left" | "center" | "right" | "justify";
}

function fontFamilyBuilder({
  italic,
  family = "dandi-grotesk",
  weight = "regular",
}: TypographyMixinProps) {
  let builder = family + "";
  if (family === "dandi-grotesk" || family === "dandi-grotesk-display") {
    return builder;
  }

  builder = family + "-";

  switch (weight) {
    case "black":
    case 900:
      builder += "black";
      break;
    case "extrabold":
    case 800:
      builder += "extrabold";
      break;
    case "bold":
    case 700:
      builder += "bold";
      break;
    case "semibold":
    case 600:
      builder += "semibold";
      break;
    case "medium":
    case 500:
      builder += "medium";
      break;
    case "regular":
    case 400:
      builder += "regular";
      break;
    case "thin":
    case 300:
      builder += "thin";
      break;
    case "light":
    case 200:
      builder += "light";
      break;
    case "extralight":
    case 100:
      builder += "extralight";
      break;
  }

  if (italic) {
    builder += "-italic";
  }

  return builder;
}

export default css<TypographyMixinProps>`
  font-family: ${fontFamilyBuilder};
  font-size: ${({ size, theme }) => getTypeSizeInPixels(size ?? 1, theme)};
  color: ${(props) => getColorString(props.color || "text", props.theme)};
  text-align: ${({ align }) => align ?? "left"};
  font-weight: ${({ weight }) => {
    if (typeof weight === "string") {
      switch (weight) {
        case "black":
        case "extrabold":
        case "bold":
        case "semibold":
        case "medium":
          return 600;
        default:
          return 400;
      }
    }
  }};
  ${({ uppercase }) =>
    uppercase === false
      ? "text-transform: none;"
      : uppercase
      ? "text-transform: uppercase;"
      : ""};
`;
