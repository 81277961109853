import { css, styled } from "styled-components";
import Spacing, { SpacingMixinProps } from "../../../theme/mixins/Spacing";
import Text from "../../Text";

interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    SpacingMixinProps {
  error?: boolean;
  errorMessage?: string;
}

const TextInput = ({ error, errorMessage, ...rest }: TextInputProps) => {
  return (
    <>
      <Container tabIndex={0} error={error} {...rest}></Container>
      {error && errorMessage && (
        <Text.CopySmall
          marginLeft='small'
          marginTop='xxsmall'
          color='text.danger.A'
        >
          {errorMessage}
        </Text.CopySmall>
      )}
    </>
  );
};

const Container = styled.input<TextInputProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme, error }) => css`
    background-color: ${theme.palette.bg.B};
    color: ${theme.palette.text.A};
    border-radius: ${theme.shape.borderRadius.xsmall}px;
    border: 1px solid
      ${error ? theme.palette.text.danger.A : theme.palette.border.C};
    padding: ${theme.spacing.sizes.medium}px;
    font-size: ${theme.typography.sizes.medium + 2}px;

    &:focus {
      background-color: ${theme.palette.bg.A};
    }

    &:focus,
    &:focus-within,
    &:focus-visible {
      outline: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 1px ${theme.palette.border.C};
    }
  `}

  ${Spacing};
`;

export default TextInput;
