import { DefaultTheme } from "styled-components";

import { defaultTheme } from "../defaultTheme";
import { palette } from "../palettes";

const DefaultDarkTheme: DefaultTheme = {
  ...defaultTheme,
  type: "dark",
  name: "default-dark",
  palette: palette.dark,
};

export default DefaultDarkTheme;
