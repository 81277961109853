import { DefaultTheme } from "styled-components";

import { SizeType, ThemePath } from "./types";

export const BASE_REM = 16;

export const rem = (size: number) => BASE_REM * size;

export function getColorString(color: ThemePath, theme: DefaultTheme): string {
  if (!theme) return "transparent";
  const parts = color.split(".");
  if (parts.length < 1) return "transparent";

  const colorType = parts[0];
  if (parts.length === 1) {
    const paletteColor = theme.palette;
    // @ts-ignore
    if (!paletteColor || !paletteColor[colorType]) return "transparent";
    // @ts-ignore
    const color = paletteColor[colorType].A;
    return color || "transparent";
  }
  if (parts.length === 2) {
    const prominence = parts[1];
    // @ts-ignore
    if (!theme?.palette || !theme?.palette[colorType]) return "transparent";
    // @ts-ignore
    const color = theme?.palette[colorType][prominence];
    return color;
  }
  if (parts.length === 3) {
    const name = parts[1];
    const prominence = parts[2];
    // @ts-ignore
    if (!theme?.palette || !theme?.palette[colorType]) return "transparent";
    // @ts-ignore
    const color = theme?.palette[colorType][name][prominence];
    return color;
  }

  return "transparent";
}

export const getSpacingInPixels = (
  value: SizeType | number,
  theme: DefaultTheme
) => {
  let output = 0;

  if (typeof value === "number") {
    output = rem(value);
  } else if (typeof value === "string") {
    // if (!theme || !theme.spacing || theme.spacing.sizes) return rem(0);
    const remValue = theme.spacing.sizes[value];
    if (!isNaN(remValue)) {
      output = remValue;
    }
  }

  return output;
};

export function getTypeSizeInPixels(
  size: SizeType | number,
  theme: DefaultTheme
) {
  let output: string = "";

  if (typeof size === "number") {
    output = size + "rem";
  } else if (typeof size === "string") {
    const remValue = theme.spacing.sizes[size];
    if (remValue) {
      output = remValue + "px";
    }
  }

  return output;
}
