import { styled } from "styled-components";
import BackgroundColor, {
  BackgroundColorMixinProps,
} from "../../theme/mixins/BackgroundColor";
import Border, { BorderMixinProps } from "../../theme/mixins/Border";
import Spacing, { SpacingMixinProps } from "../../theme/mixins/Spacing";
import Typography, {
  TypographyMixinProps,
} from "../../theme/mixins/Typography";
import Text from "../Text";

interface ButtonProps
  extends BackgroundColorMixinProps,
    TypographyMixinProps,
    BorderMixinProps,
    SpacingMixinProps,
    Omit<React.HtmlHTMLAttributes<ButtonProps>, "color"> {
  children?: React.ReactNode;
  title?: string;
  disabled?: boolean;
}

const Button = ({ children, title, ...rest }: ButtonProps) => {
  return (
    <Container {...rest}>
      <Text.Copy weight='medium' color={rest.color || "text.J"}>
        {children || title}
      </Text.Copy>
    </Container>
  );
};

const Container = styled.button<ButtonProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme, disabled }) => `
    background-color: ${
      disabled ? theme.palette.bg.A : theme.palette.bg.A + "20"
    };
    opacity: ${disabled ? 0.2 : 1};
    border: 1px solid ${theme.palette.border.A};
    border-radius: ${theme.shape.borderRadius.xlarge}px;
    color: ${theme.palette.text.J};
    padding: ${theme.spacing.sizes.small}px ${theme.spacing.sizes.large}px;

    &:focus, &:focus-within, &:focus-visible {
      outline: none;
    }

    &:focus-visible {
      border: 1px solid transparent;
      box-shadow: 0 0 0 2px ${theme.palette.border.D};
    }
  `}

  ${Typography};
  ${BackgroundColor};
  ${Border};
  ${Spacing};
`;

Button.Primary = function ButtonPrimary(props: ButtonProps) {
  return <Button weight='medium' bgColor='bg.J' color='text.J' {...props} />;
};

Button.Secondary = function ButtonSecondary(props: ButtonProps) {
  return <Button color='text' {...props} />;
};

export default Button;
