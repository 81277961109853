import { useEffect } from "react";

function focusNextItem(list: any[]) {
  const activeItem: any = document.activeElement;
  if (activeItem?.nextElementSibling) {
    console.log("I'm triggering once.");
    deactivateAll(list);
    activate(activeItem.nextElementSibling);
  }
}

function focusPreviousItem(list: any[]) {
  const activeItem: any = document.activeElement;
  if (activeItem?.previousElementSibling) {
    deactivateAll(list);
    activate(activeItem.previousElementSibling);
  }
}

function deactivateAll(list: any[]) {
  list.forEach((item: any) => {
    item.tabIndex = -1;
  });
}

function activate(item: any) {
  item.tabIndex = 0;
  item.focus();
}

const useKeyboardNavigation = ({
  ref,
  trigger,
  disabled,
}: {
  ref: any;
  trigger: any;
  disabled: boolean;
}) => {
  useEffect(() => {
    if (!ref?.current || disabled) return;
    const items = Array.from(ref.current.children);

    function onKeyDown(e: any) {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          e.stopPropagation();
          focusNextItem(items);
          break;
        case "ArrowRight":
          e.preventDefault();
          e.stopPropagation();
          focusNextItem(items);
          break;
        case "ArrowLeft":
          e.preventDefault();
          e.stopPropagation();
          focusPreviousItem(items);
          break;
        case "ArrowUp":
          e.preventDefault();
          e.stopPropagation();
          focusPreviousItem(items);
          break;
        case "Enter":
          e.preventDefault();
          e.stopPropagation();
          e.target.click();
          break;
        case "Space":
          e.preventDefault();
          e.stopPropagation();
          e.target.click();
          break;
        default:
          break;
      }

      return;
    }

    function onClick(e: any) {
      if (items.indexOf(e.target) == -1) {
        return;
      }
      deactivateAll(items);
      activate(e.target);
    }

    ref.current.addEventListener("keydown", onKeyDown);
    ref.current.addEventListener("click", onClick);

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("keydown", onKeyDown);
        ref.current.removeEventListener("click", onClick);
      }
    };
  }, [ref?.current, trigger, disabled]);

  return {};
};

export default useKeyboardNavigation;
