import { initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

const firebaseConfig =
  process.env.REACT_APP_ENV !== "prod"
    ? {
        apiKey: "AIzaSyBtG3dD2UejrGJ8WAzniTznV9MbBlF12js",
        authDomain: "dandi-dev.firebaseapp.com",
        storageBucket: "dandi-dev.appspot.com",
        databaseURL: "https://dandi-dev.firebaseio.com",
        projectId: "dandi-dev",
        appId: "1:667507631616:web:80d60124702ff195eb5ae5",
      }
    : {
        apiKey: "AIzaSyDQmaVVbMRTeFbslqJzfa0SnAEdVe5tfc4",
        authDomain: "dandi-184419.firebaseapp.com",
        databaseURL: "https://dandi-184419.firebaseio.com",
        projectId: "dandi-184419",
        storageBucket: "dandi-184419.appspot.com",
        appId: "1:11027589147:web:cae15c506af0b4b4fbb0be",
      };

export const app = initializeApp(firebaseConfig);

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.REACT_APP_ENV !== "prod"
      ? "6LfwNQIoAAAAAAc8fxz8zKbsSYpcA8JQvJ7QJsal"
      : "6Lc_s34oAAAAABskIrB9QBw9H9pnfqCl-9oau794"
  ),
  isTokenAutoRefreshEnabled: true,
});
