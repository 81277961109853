import { css, DefaultTheme } from "styled-components";

import { BorderRadii, ThemePath } from "../types";
import { getColorString } from "../utils";

export interface BorderMixinProps {
  borderColor?: ThemePath;
  borderRadius?: number | BorderRadii;
  borderTopLeftRadius?: number | BorderRadii;
  borderTopRightRadius?: number | BorderRadii;
  borderBottomLeftRadius?: number | BorderRadii;
  borderBottomRightRadius?: number | BorderRadii;
  borderWidth?: number | BorderRadii;
  borderLeftWidth?: number;
  borderRightWidth?: number;
  borderTopWidth?: number;
  borderBottomWidth?: number;
}

export function mapSizePropValueToPixels({
  value,
  theme,
}: {
  value: number | BorderRadii;
  theme: DefaultTheme;
}) {
  let output = 0;

  if (typeof value === "number") {
    return value;
  } else if (typeof value === "string") {
    const namedValue = theme.shape.borderRadius[value];
    if (!isNaN(namedValue)) {
      output = namedValue;
    }
  }

  return output;
}

function mapBorderRadiusPropsToStyles(
  props: BorderMixinProps,
  theme: DefaultTheme
) {
  return Object.entries(props)
    .filter((p) => p[0].toLowerCase().match(/radius/))
    .map(
      (p) =>
        `${p[0]
          .split(/(?=[A-Z])/)
          .map((v) => v.toLowerCase())
          .join("-")}: ${mapSizePropValueToPixels({ value: p[1], theme })}px`
    )
    .join("\n");
}

export default css<BorderMixinProps>`
  border-color: ${({ borderColor, theme }) =>
    borderColor ? getColorString(borderColor, theme) : "transparent"};
  ${({ borderWidth }) => borderWidth && `border-width: ${borderWidth}px`}
  ${({ borderLeftWidth }) =>
    borderLeftWidth && `border-left-width: ${borderLeftWidth}px`}
  ${({ borderTopWidth }) =>
    borderTopWidth && `border-top-width: ${borderTopWidth}px`}
  ${({ borderRightWidth }) =>
    borderRightWidth && `border-right-width: ${borderRightWidth}px`}
  ${({ borderBottomWidth }) =>
    borderBottomWidth && `border-bottom-width: ${borderBottomWidth}px`}
  ${(props) => mapBorderRadiusPropsToStyles(props, props.theme)};
`;
