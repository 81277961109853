import React from "react";
import styled from "styled-components";
import BackgroundColor, {
  BackgroundColorMixinProps,
} from "../../theme/mixins/BackgroundColor";
import Border, { BorderMixinProps } from "../../theme/mixins/Border";
import Spacing, { SpacingMixinProps } from "../../theme/mixins/Spacing";
import Typography, {
  TypographyMixinProps,
} from "../../theme/mixins/Typography";

interface DivProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "color">,
    TypographyMixinProps,
    BackgroundColorMixinProps,
    SpacingMixinProps,
    BorderMixinProps {}

const Div = styled.div<DivProps>`
  ${Typography};
  ${BackgroundColor};
  ${Spacing};
  ${Border};
`;
export default Div;
