import styled from "styled-components";
import { IconProps } from "../types";

interface XProps extends IconProps {}

const X = ({ size = 24 }: XProps) => {
  const width = (size / 23) * size;
  const height = (size / 23) * size;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_1_4335)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M19.495 3.70691L18.7879 2.9998L11.5008 10.2869L4.21533 3.00146L3.50823 3.70857L10.7937 10.994L3.00049 18.7872L3.70759 19.4943L11.5008 11.7011L19.2956 19.496L20.0027 18.7889L12.2079 10.994L19.495 3.70691Z'
          fill='currentColor'
          fill-opacity='0.75'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_4335'>
          <rect width='23' height='23' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

const Container = styled.div``;
export default X;
