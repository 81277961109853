import { IconProps } from "../types";

interface InfoSheldProps extends IconProps {}

const InfoShield = ({ size = 24 }: InfoSheldProps) => {
  const width = (size / 17) * size;
  const height = (size / 21) * size;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.78117 15.1958H9.21867V9.03853H7.78117V15.1958ZM8.49992 7.45728C8.73072 7.45728 8.92418 7.37922 9.08031 7.22309C9.23644 7.06696 9.3145 6.8735 9.3145 6.6427C9.3145 6.4119 9.23644 6.21844 9.08031 6.06231C8.92418 5.90618 8.73072 5.82812 8.49992 5.82812C8.26912 5.82812 8.07566 5.90618 7.91953 6.06231C7.7634 6.21844 7.68534 6.4119 7.68534 6.6427C7.68534 6.8735 7.7634 7.06696 7.91953 7.22309C8.07566 7.37922 8.26912 7.45728 8.49992 7.45728ZM8.49992 20.0594C6.26381 19.5003 4.427 18.2026 2.9895 16.1661C1.552 14.1297 0.833252 11.8976 0.833252 9.46978V3.7677L8.49992 0.8927L16.1666 3.7677V9.46978C16.1666 11.8976 15.4478 14.1297 14.0103 16.1661C12.5728 18.2026 10.736 19.5003 8.49992 20.0594ZM8.49992 18.5739C10.3367 17.967 11.8341 16.821 12.9921 15.1359C14.1501 13.4509 14.7291 11.5621 14.7291 9.46978V4.77395L8.49992 2.42603L2.27075 4.77395V9.46978C2.27075 11.5621 2.84975 13.4509 4.00773 15.1359C5.16572 16.821 6.66311 17.967 8.49992 18.5739Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default InfoShield;
