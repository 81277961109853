import styled, { css } from "styled-components";
import GlobalsCss from "./Globals.css";
import notFoundImage from "./assets/img/404-asset.png";
import { Box, Screen } from "./ui/primitives";

interface NotFoundProps {}

const NotFound = (props: NotFoundProps) => {
  return (
    <Screen bgColor='bg.C'>
      <GlobalsCss />
      <StyledBox bgColor='bg.C'>
        <StyledImage src={notFoundImage}></StyledImage>
        <TextContainer>
          <StyledH1 style={{ fontFamily: "dandi-grotesk=display" }}>
            Oops... Something went wrong
          </StyledH1>
        </TextContainer>
      </StyledBox>
    </Screen>
  );
};

const StyledBox = styled(Box)`
  padding: 0;

  @media screen and (max-width: 768px) {
    display: flex;
    height: 100svh;
    align-items: center;
    justify-content: center;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

const StyledH1 = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.xxlarge}px;

    @media screen and (max-width: 768px) {
      font-size: ${theme.typography.sizes.large}px;
    }
  `}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sizes.xxlarge}px
    ${({ theme }) => theme.spacing.sizes.medium}px;

  ${({ theme }) => css`
    background-color: ${theme.palette.bg.A};
  `}
`;

export default NotFound;
