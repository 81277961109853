import { createGlobalStyle, css } from "styled-components";

export default createGlobalStyle`
  @font-face {
    font-family: 'dandi-grotesk';
    src: url('assets/fonts/subset-DandiGrotesk-Medium.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
  @font-face {
    font-family: 'dandi-grotesk';
    src: url('assets/fonts/subset-DandiGrotesk-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
  @font-face {
    font-family: 'dandi-grotesk-display';
    src: url('assets/fonts/subset-DandiGroteskDisplay-Medium.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@media screen and (max-width: 767px) {
    _::-webkit-full-page-media,
    _:future,
    :root .safari_only {
      padding-bottom: 65px; //resize
    }
  }

  html, div, p, h1, h2, h3, h4, h5, h6, span, body, header, main, ul, li {
    padding: 0;
    margin: 0;
  }

  body {
    transition: background-color 1s ease-in-out;
    overflow-x: hidden;
    padding-bottom: env(safe-area-inset-bottom);
    height: 100svh;
    width: 100svw;

  }

  html {
    overflow-x: hidden;
    font-size: 16px;
    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
      font-size: 16px;
    }
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
      font-size: 16px;
    }
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
      font-size: 16px;
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
      font-size: 16px;
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
      font-size: 16px;
    }
    font-family: ${({ theme }) =>
      theme.typography.families
        .sansSerif}, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

    color: ${({ theme }) => theme.palette.text.A};
    /* ${({ theme }) => css`
      background: url(${theme.variant === "dark"
          ? "/assets/background/WhiteDots.png"
          : "/assets/background/BlackDots.png"})
        repeat;
    `} */
    /* background: url('/assets/background/WhiteDots.webp') repeat; */
    background-color: ${({ theme }) => theme.palette.bg.A};
    line-height: 1.5;
  }

  h1, h2, h3, h4, h5, h6 {
    ${({ theme }) => `
      font-family: ${theme.typography.families.sansSerif}, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;;
    `}
  }

  .decorative {
    font-family: ${({ theme }) => theme.typography.families.monospace};
  }

  pre, code, .code {
    font-family: ${({ theme }) =>
      theme.typography.families.monospace}, monospace;

  }

  a {
    color: ${({ theme }) => theme.palette.text.brand.A};
    text-decoration: none;
  }


  * {
   box-sizing: border-box;
  }

  h1 {
    font-size: ${({ theme }) => theme.typography.sizes.xxlarge}px;
    font-weight: 700;
  }
  h2 {
    font-size: ${({ theme }) => theme.typography.sizes.xlarge}px;
    font-weight: 700;
  }
  h2 {
    font-size: ${({ theme }) => theme.typography.sizes.large}px;
    font-weight: 600;
  }

  ::selection {
    ${({ theme }) => `
      background: ${theme.palette.bg.E};
      color: ${theme.palette.text.highlight};
    `}
  }
  ::-moz-selection {
    ${({ theme }) => `
      background: ${theme.palette.bg.E};
      color: ${theme.palette.text.highlight};
    `}
  }

  *:focus {
    //outline: 2px solid red !important;
  }

  @keyframes glowing {
    ${({ theme }) => css`
      0% {
        background-color: ${theme.palette.bg.highlight.J};
        /* box-shadow: 0 0 3px ${theme.palette.bg.D}; */
      }
      50% {
        background-color: ${theme.palette.bg.highlight.J + "80"};
        /* box-shadow: 0 0 10px ${theme.palette.bg.A}; */
      }
      100% {
        background-color: ${theme.palette.bg.highlight.J};
        /* box-shadow: 0 0 3px ${theme.palette.bg.D}; */
      }
    `}
}

@keyframes slideout {
  0% { transform: translateX(0); visibility: visible; display: block; }
  99% { visibility: visible; display: block;}
  100% { transform: translateX(100%); visibility: hidden; display:none;}
}

@keyframes slidein {
  0% { transform: translateX(100%); visibility: visible; display: none; }
  1% { visibility: visible; display: block;}
  100% { transform: translateX(0); visibility: visible; display: block; }
}

`;
