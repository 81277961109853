import React from "react";
import styled from "styled-components";

import BackgroundColor, {
  BackgroundColorMixinProps,
} from "../../../ui/theme/mixins/BackgroundColor";
import Border, { BorderMixinProps } from "../../../ui/theme/mixins/Border";
import Spacing, { SpacingMixinProps } from "../../../ui/theme/mixins/Spacing";
import Typography, {
  TypographyMixinProps,
} from "../../../ui/theme/mixins/Typography";

export interface TextProps
  extends TypographyMixinProps,
    BackgroundColorMixinProps,
    BorderMixinProps,
    SpacingMixinProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

/**
 * Generic text component that allows for all types of styling using the theme
 * Has additional variants inside:
 * - `Text.H1`
 * - `Text.H2`
 * - `Text.H3`
 * - `Text.Copy`
 * - `Text.CopySmall`
 * - `Text.Code`
 */
const Text = ({ children, style, ...props }: TextProps) => {
  return (
    <StyledText {...props} color={props.color} style={style}>
      {children}
    </StyledText>
  );
};

const StyledText = styled("span")<TextProps>`
  ${Typography};
  ${BackgroundColor};
  ${Spacing};
  ${Border};
`;

/** Header 1 `Text` component variant */
Text.H1 = function TextH1(props: TextProps) {
  return <Text size='xxlarge' weight='extrabold' {...props} />;
};

/** Header 2 `Text` component variant */
Text.H2 = function TextH2(props: TextProps) {
  return <Text size='large' weight='bold' {...props} />;
};

/** Header 3 `Text` component variant */
Text.H3 = function TextH3(props: TextProps) {
  return <Text size='large' weight='medium' {...props} />;
};

/** Copy `Text` component variant */
Text.Copy = function TextCopy(props: TextProps) {
  return <Text size='medium' {...props} />;
};

/** Small copy `Text` component variant */
Text.CopySmall = function TextCopySmall(props: TextProps) {
  return <Text size='small' {...props} />;
};

/** Monospaced font `Text` component variant */
Text.Code = function TextCode(props: TextProps) {
  return <Text size='medium' family='jetbrains' {...props} />;
};

/** All the available text variants */
export type TextVariants = keyof typeof Text;

export default Text;
