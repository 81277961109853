import styled, { css } from "styled-components";
import Icon from "../../Icon";
import Text from "../../Text";

interface AnsweredSearchProps {
  children?: React.ReactNode;
  onDelete?: () => void;
  tabIndex?: number;
}

const AnsweredSearch = ({
  children,
  onDelete,
  tabIndex,
}: AnsweredSearchProps) => {
  return (
    <Container onClick={onDelete} role='button' tabIndex={tabIndex}>
      <Text.Copy weight='medium' size={1.125}>
        {children}
      </Text.Copy>
      <Icon name='X' size={19} />
    </Container>
  );
};

const Container = styled.button`
  all: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => css`
    background-color: ${theme.palette.bg.A};
    box-shadow: 0 5px 10px ${theme.palette.bg.D + "80"};
    border-radius: ${theme.shape.borderRadius.xsmall}px;
    padding: ${theme.spacing.sizes.small}px;
    font-size: ${theme.typography.sizes.medium + 2}px;

    &:focus,
    &:focus-within,
    &:focus-visible {
      outline: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px ${theme.palette.border.C};
    }
  `}
`;
export default AnsweredSearch;
