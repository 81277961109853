import { styled, useTheme } from "styled-components";
import GlobalsCss from "../Globals.css";
import { KeypressProvider } from "../providers/KeypressProvider";
import { SurveyProgressProvider } from "../providers/SurveyProgressProvider";

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const theme = useTheme();
  return (
    <Container>
      <SurveyProgressProvider>
        <KeypressProvider>
          <GlobalsCss theme={theme} />
          {children}
        </KeypressProvider>
      </SurveyProgressProvider>
    </Container>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100svw;
  height: 100svh;
  overflow-x: hidden;
`;

export default Layout;
