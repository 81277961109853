import { css } from "styled-components";

import { ThemePath } from "../types";
import { getColorString } from "../utils";

export interface BackgroundColorMixinProps {
  bgColor?: ThemePath;
}

export default css<BackgroundColorMixinProps>`
  background-color: ${({ theme, bgColor }) =>
    bgColor ? getColorString(bgColor, theme) : "transparent"};
`;
