import React from "react";

export function useSmallScreen(): boolean {
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);

  React.useEffect(() => {
    if (!window || typeof window === "undefined" || !window.removeEventListener)
      return;
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      if (
        !window ||
        typeof window === "undefined" ||
        !window.removeEventListener
      )
        return;
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isSmallScreen;
}
