import React from "react";
import { css, styled } from "styled-components";
import DandiInfo from "../../../components/DandiInfo";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { useSmallScreen } from "../../../hooks/useSmallScreen";
import shouldForwardProp from "../../../utils/props";
import BackgroundColor, {
  BackgroundColorMixinProps,
} from "../../theme/mixins/BackgroundColor";
import Border, { BorderMixinProps } from "../../theme/mixins/Border";
import Spacing, { SpacingMixinProps } from "../../theme/mixins/Spacing";

interface ScreenProps
  extends BackgroundColorMixinProps,
    SpacingMixinProps,
    BorderMixinProps {
  children?: React.ReactNode;
  /** Disable safe area paddings.
   *
   * `false` by default
   */
  safe?: boolean;
  /** Adds safe space on the bottom. Overrides `safe` prop.
   *
   * `false` by default  */
  safeBottom?: boolean;
  /** Adds safe space on the top. Overrides `safe` prop.
   *
   *`true` by default  */
  safeTop?: boolean;
  /** Adds footer */
  footer?: React.ReactNode;
  /** Adds sidebar */
  sidebar?: React.ReactNode;
  /** Adds header */
  header?: React.ReactNode;

  /** is sidebar shown */
  sidebarOpen?: boolean;
  /** toggleSidebar */
  toggleSidebar?: () => void;

  /** wether to show Dandi info. */
  showDandi?: boolean;
}

const Screen = React.forwardRef(
  (
    {
      children,
      footer,
      safe = true,
      sidebar,
      header,
      sidebarOpen,
      toggleSidebar,
      showDandi = false,
      ...rest
    }: ScreenProps,
    ref
  ) => {
    const safeTop = rest.safeTop || safe;
    const safeBottom = rest.safeBottom || safe;
    const isSmallScreen = useSmallScreen();
    const { width } = useScreenSize();

    return (
      <MainContainer
      // @ts-ignore
      // ref={ref}
      >
        <Base
        // @ts-ignore
        // ref={ref}
        >
          {header}
          <Container
            // @ts-ignore
            ref={ref}
            safe={safe}
            safeTop={safeTop}
            safeBottom={safeBottom}
            {...rest}
          >
            {children}
          </Container>
          {footer}
        </Base>
        <SidebarContainer isOpen={isSmallScreen ? sidebarOpen : true}>
          {sidebar}
        </SidebarContainer>
        {showDandi && width > 1000 && (
          <DandiInfoContainer>
            <DandiInfo />
          </DandiInfoContainer>
        )}
      </MainContainer>
    );
  }
);

const MainContainer = styled("div")`
  display: flex;
  flex-direction: row;
  flex: 1;
  /* height: 100vh;
  width: 100vw; */
  min-height: -webkit-fill-available;
  /* overflow-y: hidden; */
  overflow-x: hidden;
  height: 100svh;
  width: 100svw;
`;

const DandiInfoContainer = styled("div")`
  position: absolute;
  bottom: 0;
  left: 0;

  ${({ theme }) => css`
    padding-left: ${theme.spacing.sizes.xlarge}px;
    padding-bottom: ${theme.spacing.sizes.xlarge}px;
  `}
`;

interface SidebarContainerProps {
  isOpen?: boolean;
}

const SidebarContainer = styled("div")<SidebarContainerProps>`
  @media screen and (max-width: 767px) {
    ${({ isOpen }) =>
      !isOpen
        ? css`
            animation: slideout 0.5s forwards;
          `
        : css`
            animation: slidein 0.5s forwards;
          `}
  }
  transition: transform 0.3s ease-out;
  overflow-x: hidden;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    position: absolute;
    right: 0;
    top: 0;
    flex: 1;
    height: 100svh;
    width: 100svw;
  }
`;

const HeaderContainer = styled("div")`
  @media screen and (max-width: 767px) {
    _::-webkit-full-page-media,
    _:future,
    :root .safari_only {
      padding-bottom: 65px; //resize
    }
  }
`;
const Base = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100svh;
  width: 100svw;
`;

const Container = styled("div").withConfig({
  shouldForwardProp: (prop) => shouldForwardProp(["bgColor"], prop),
})<ScreenProps>`
  ${Border};
  ${BackgroundColor};
  ${Spacing};

  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;

  transition: background-color 1s ease-in-out;

  ${({ theme, safe, safeBottom, safeTop }) => css`
    /* padding-top: ${safe ? theme.spacing.sizes.xlarge : 0}px; */
    /* padding-bottom: ${safeBottom ? theme.spacing.sizes.xlarge : 0}px; */
    /* padding-bottom: ${safeTop ? theme.spacing.sizes.xlarge : 0}px; */
  `};
  overflow-y: hidden;
  overflow-x: hidden;

  /* padding-bottom: 200px; */
`;

export default Screen;
