import { IconProps } from "../types";

interface CheckboxProps extends IconProps {}

const Checkbox = ({ size = 24 }: CheckboxProps) => {
  const width = (size / 16) * size;
  const height = (size / 15) * size;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_382_403)'>
        <mask id='path-1-inside-1_382_403' fill='white'>
          <path d='M12.2775 2H3.72198C3.04976 2 2.49976 2.55 2.49976 3.22222V11.7778C2.49976 12.45 3.04976 13 3.72198 13H12.2775C12.9498 13 13.4998 12.45 13.4998 11.7778V3.22222C13.4998 2.55 12.9498 2 12.2775 2ZM12.4998 12H3.49976V3H12.4998V12Z' />
        </mask>
        <path
          d='M12.2775 2H3.72198C3.04976 2 2.49976 2.55 2.49976 3.22222V11.7778C2.49976 12.45 3.04976 13 3.72198 13H12.2775C12.9498 13 13.4998 12.45 13.4998 11.7778V3.22222C13.4998 2.55 12.9498 2 12.2775 2ZM12.4998 12H3.49976V3H12.4998V12Z'
          fill='currentColor'
        />
        <path
          d='M12.4998 12V15H15.4998V12H12.4998ZM3.49976 12H0.499756V15H3.49976V12ZM3.49976 3V0H0.499756V3H3.49976ZM12.4998 3H15.4998V0H12.4998V3ZM12.2775 -1H3.72198V5H12.2775V-1ZM3.72198 -1C1.3929 -1 -0.500244 0.893146 -0.500244 3.22222H5.49976C5.49976 4.20685 4.70661 5 3.72198 5V-1ZM-0.500244 3.22222V11.7778H5.49976V3.22222H-0.500244ZM-0.500244 11.7778C-0.500244 14.1069 1.3929 16 3.72198 16V10C4.70661 10 5.49976 10.7931 5.49976 11.7778H-0.500244ZM3.72198 16H12.2775V10H3.72198V16ZM12.2775 16C14.6066 16 16.4998 14.1069 16.4998 11.7778H10.4998C10.4998 10.7931 11.2929 10 12.2775 10V16ZM16.4998 11.7778V3.22222H10.4998V11.7778H16.4998ZM16.4998 3.22222C16.4998 0.893149 14.6066 -1 12.2775 -1V5C11.2929 5 10.4998 4.20685 10.4998 3.22222H16.4998ZM12.4998 9H3.49976V15H12.4998V9ZM6.49976 12V3H0.499756V12H6.49976ZM3.49976 6H12.4998V0H3.49976V6ZM9.49976 3V12H15.4998V3H9.49976Z'
          fill='currentColor'
          mask='url(#path-1-inside-1_382_403)'
        />
        <path
          d='M10.8125 5.625L6.77011 9.375L5.1875 7.91256'
          stroke='currentColor'
          stroke-linecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_382_403'>
          <rect
            width='16'
            height='15'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Checkbox;
