import styled, { css } from "styled-components";
import { useSmallScreen } from "../../hooks/useSmallScreen";
import { useSurveyProgress } from "../../providers/SurveyProgressProvider";
import { Icon } from "../../ui/primitives";
import ImageContainer from "../ImageContainer";

interface HeaderProps {
  showSidebar?: () => void;
  showHamburger?: boolean;
  noLogo?: boolean;
}

const Header = ({ showSidebar, showHamburger, noLogo }: HeaderProps) => {
  const smallScreen = useSmallScreen();
  const { progress } = useSurveyProgress();

  const logo = progress?.meta.uiElements.find(
    (u) => u.key === "logoLightURL"
  )?.value;

  if (!progress) return null;

  if (smallScreen)
    return (
      <PhoneContainer>
        <PhoneTitle>
          {logo && !noLogo ? (
            <ImageContainer
              imageUrl={logo}
              width={100}
              height={40}
              borderRadius="small"
            />
          ) : (
            <DandiLogoContainer>
              <Icon name="Dandi" size={28} />
            </DandiLogoContainer>
          )}
        </PhoneTitle>
        {showHamburger && (
          <Icon
            name="Hamburger"
            size={20}
            onClick={() => {
              showSidebar && showSidebar();
            }}
          />
        )}
      </PhoneContainer>
    );

  return (
    <Container>
      {logo && !noLogo ? (
        <ImageContainer
          imageUrl={logo}
          width={104}
          height={44}
          borderRadius="small"
        />
      ) : noLogo ? null : (
        <DandiLogoContainer>
          <Icon name="Dandi" size={28} />
        </DandiLogoContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: background-color 1s ease-in-out;

  ${({ theme }) => css`
    padding: ${theme.spacing.sizes.large}px;
    background-color: transparent;
  `}
`;

const DandiLogoContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.sizes.small}px;
  `}
`;

const PhoneContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    background-color: ${theme.palette.bg.A};
    padding: ${theme.spacing.sizes.medium}px ${theme.spacing.sizes.large}px;
  `}
`;

const PhoneTitle = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export default Header;
