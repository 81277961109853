import axios from "axios";
import { compareAsc, endOfDay, format } from "date-fns";
import { getToken } from "firebase/app-check";
import React from "react";
import * as sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";
import { ISurvey } from "../types";
import { CURRENT_PROGRESS, ENCRYPTED_PATH } from "../utils/consts";
import { appCheck } from "../utils/firebase";
import {
  getFromLocalStorage,
  getFromSessionStorage,
  removeFromLocalStorage,
  removeFromSessionStorage,
  saveToLocalStorage,
  saveToSessionStorage,
} from "../utils/storageSave";

const CONTENT_API =
  process.env.REACT_APP_ENV === "prod"
    ? "https://content.itsdandi.com"
    : "https://content.dandi.tech";

const ANSWER_API =
  process.env.REACT_APP_ENV === "prod"
    ? "https://dandi-collection-edge-server.itsdandi.com"
    : "https://dandi-collection-edge-server.dandi.tech";

interface IProgressProvider {
  progress?: ISurvey;
  updateProgress: (newProgress: ISurvey) => void;
  fetchSurvey: () => void;
  fetchSurveyAuth: (
    surveyOrGlobalSurveyId: string,
    enterpriseId: string,
    accessToken: string,
    rbacToken: string
  ) => void;
  employeeId: string;
  setEmployeeId: (s: string) => void;
  password: string;
  setPassword: (s: string) => void;
  wrongPassword: boolean;
  isValidSurvey: boolean;
  setP1: (s: string) => void;
  submitAnswers: () => Promise<void>;
  canBeSubmitted: boolean;
  loading?: boolean;
  loaded?: boolean;
}

const initialContext: IProgressProvider = {
  progress: undefined,
  updateProgress: () => { },
  fetchSurvey: () => { },
  fetchSurveyAuth: () => { },
  employeeId: "",
  password: "",
  setEmployeeId: () => { },
  setPassword: () => { },
  setP1: () => { },
  submitAnswers: async () => { },
  wrongPassword: false,
  isValidSurvey: true,
  canBeSubmitted: false,
  loading: false,
  loaded: false,
};

const SurveyProgressContext =
  React.createContext<IProgressProvider>(initialContext);

interface SurveyProgressProviderProps {
  children: React.ReactNode;
}

const SurveyProgressProvider = ({ children }: SurveyProgressProviderProps) => {
  const [progress, setProgress] = React.useState<ISurvey | undefined>(
    initialContext.progress
  );
  const [progressLoaded, setProgressLoaded] = React.useState(false);
  const [employeeId, setEmployeeId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [wrongPassword, setWrongPassword] = React.useState(true);
  const [p1, setP1] = React.useState("");
  const [sessionId, setSessionId] = React.useState<string>();
  const [canBeSubmitted, setCanBeSubmitted] = React.useState(false);
  const [isValidSurvey, setIsValidSurvey] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const updateProgress = (newProgress: ISurvey) => {
    setProgress(newProgress);
  };

  React.useEffect(() => {
    let savedProgress;
    if (getFromSessionStorage("preview") === "true") {
      savedProgress = getFromSessionStorage(CURRENT_PROGRESS);
    } else {
      savedProgress = getFromLocalStorage(CURRENT_PROGRESS);
    }
    const searchParams = new URLSearchParams(window.location.search);

    if (
      searchParams.has("id") &&
      searchParams.has("enterpriseId") &&
      searchParams.has("accessToken") &&
      searchParams.has("rbacToken")
    ) {
      saveToSessionStorage("preview", "true");
      fetchSurveyAuth(
        searchParams.get("id")!,
        searchParams.get("enterpriseId")!,
        searchParams.get("accessToken")!,
        searchParams.get("rbacToken")!
      ).then(() => {
        setProgressLoaded(true);
      });
    } else if (savedProgress) {
      setProgress(JSON.parse(savedProgress));
      setProgressLoaded(true);
    } else {
      setProgressLoaded(true);
    }
  }, []);

  React.useEffect(() => {
    // setWrongPassword(true);
  }, [password, employeeId]);

  React.useEffect(() => {
    saveToLocalStorage(ENCRYPTED_PATH, p1);
  }, [p1]);

  React.useEffect(() => {
    if (!progressLoaded) return;

    if (getFromSessionStorage("preview") === "true") {
      saveToSessionStorage(CURRENT_PROGRESS, JSON.stringify(progress));
    } else {
      saveToLocalStorage(CURRENT_PROGRESS, JSON.stringify(progress));
    }

    // can be submitted only if every question has at least one value with a score > 0
    const canBeSubmitted =
      progress?.categories.every((category) => {
        return category.questions.every((question) => {
          return question.values.some((value) => value.score > 0);
        });
      }) || false;

    setCanBeSubmitted(canBeSubmitted);
  }, [progress]);

  const validateSurvey = (survey: ISurvey) => {
    const endOfToday = format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ssXX");
    const endOfSurvey = survey.endDate;
    const hasExpired =
      compareAsc(new Date(endOfToday), new Date(endOfSurvey)) === 1;

    return !hasExpired && survey.isPublished;
  };

  const fetchSurvey = async function() {
    setLoading(true);
    try {
      const tokenResponse = await getToken(appCheck, false);
      const response = await axios.post(
        `${CONTENT_API}/surveys/published/${p1}`,
        {
          employeeId: employeeId ? employeeId : undefined,
          password,
        },
        {
          headers: {
            "X-Firebase-AppCheck": tokenResponse.token,
          },
        }
      );

      setIsValidSurvey(validateSurvey(response.data.survey));

      setWrongPassword(false);
      setProgress(response.data.survey);
      setSessionId(uuidv4());
    } catch (e: any) {
      if (e.response?.status === 400) {
        setWrongPassword(true);
      }

      sentry.captureException({
        employeeId: employeeId ? employeeId : "undefined",
        pw: password,
        message: e?.message,
        status: e.response?.status,
      });
    }
    setLoading(false);
  };

  const fetchSurveyAuth = async function(
    surveyOrGlobalSurveyId: string,
    enterpriseId: string,
    accessToken: string,
    rbacToken: string
  ) {
    setLoading(true);
    try {
      const response = await axios.post(
        `${CONTENT_API}/surveys/enterprise/id`,
        {
          enterpriseId,
          surveyOrGlobalSurveyId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "X-Endpoint-RBAC-Token": rbacToken,
          },
        }
      );

      setWrongPassword(false);
      setIsValidSurvey(validateSurvey(response.data.survey[0]));
      setProgress(response.data.survey[0]);
    } catch (e: any) {
      if (e.response?.status === 400) {
        setWrongPassword(true);
      }
      console.log(e.message);
    }
    setLoading(false);
  };

  const submitAnswers = async () => {
    setLoading(true);

    try {
      if (getFromSessionStorage("preview") === "true") {
        // In preview mode, don't submit
        removeFromSessionStorage(CURRENT_PROGRESS);
        setLoading(false);
        return;
      }
      const tokenResponse = await getToken(appCheck, false);

      if (!progress) return;

      const {
        enterpriseId,
        audienceId,
        globalSurveyId,
        surveyId,
        privacy,
        storage,
        score: surveyScore,
        endDate,
      } = progress;

      const endDateUtc = endDate;
      const eventTimeLocalSubmission = new Date().toISOString();
      const eventTimeUtcSubmission = new Date().toISOString();

      const answers: any[] = [];
      progress.categories.forEach((category) => {
        category.questions.forEach((question, index) => {
          const {
            questionId,
            globalQuestionId,
            globalCategoryId,
            categoryId,
            fieldName,
            valueType,
            score: questionScore,
            values,
          } = question;
          const { score: categoryScore } = category;
          let singularAnswerValue;
          let multiAnswerValues;
          const answeredValues = values
            .filter((v) => v.score !== 0)
            .map((v) => v.value);
          if (answeredValues.length === 0) {
            // User did not select an answer
            return;
          }
          if (valueType === "CHECKBOX" || valueType === "MULTIPLE_CHOICE") {
            multiAnswerValues = answeredValues;
          } else {
            singularAnswerValue = answeredValues[0];
          }

          const isLast = index === category.questions.length - 1;

          const pageIndex = isLast ? -1 : index + 1;
          const eventTimeUtc = isLast
            ? eventTimeUtcSubmission
            : question.eventTimeUtc;
          const eventTimeLocal = isLast
            ? eventTimeLocalSubmission
            : question.eventTimeLocal;

          answers.push({
            sessionId,
            enterpriseId,
            audienceId,
            employeeId,
            globalSurveyId,
            surveyId,
            surveyScore,
            globalCategoryId,
            categoryId,
            categoryScore,
            globalQuestionId,
            questionId,
            questionScore,
            questionFieldName: fieldName,
            singularAnswerValue,
            multiAnswerValues,
            answerScore: 0,
            answerValueType: valueType,
            location: storage.type,
            privacy: privacy.type,
            endDateUtc,
            eventTimeUtc,
            eventTimeLocal,
            page: pageIndex,
            meta: [
              {
                key: "string",
                value: "string",
              },
            ],
          });
        });
      });

      const response = await axios.put(
        `${ANSWER_API}/answer`,
        {
          sessionId,
          enterpriseId,
          audienceId,
          employeeId,
          globalSurveyId,
          surveyId,
          answers,
          location: storage.type,
          privacy: privacy.type,
          endDateUtc,
          eventTimeUtc: eventTimeUtcSubmission,
          eventTimeLocal: eventTimeLocalSubmission,
        },
        {
          headers: {
            "X-Firebase-AppCheck": tokenResponse.token,
          },
        }
      );
      console.log(response.data);
      removeFromLocalStorage(CURRENT_PROGRESS);
    } catch (e: any) {
      console.log(e.message);
    }
    setLoading(false);
  };

  return (
    <SurveyProgressContext.Provider
      value={{
        progress,
        updateProgress,
        fetchSurvey,
        employeeId,
        setEmployeeId,
        password,
        setPassword,
        setP1,
        submitAnswers,
        fetchSurveyAuth,
        canBeSubmitted,
        wrongPassword,
        isValidSurvey,
        loading,
        loaded: progressLoaded,
      }}
    >
      {children}
    </SurveyProgressContext.Provider>
  );
};

const useSurveyProgress = () => React.useContext(SurveyProgressContext);

export { SurveyProgressProvider, useSurveyProgress };
