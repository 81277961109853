import React from "react";
import { css, styled } from "styled-components";
import BackgroundColor, {
  BackgroundColorMixinProps,
} from "../../theme/mixins/BackgroundColor";
import Border, { BorderMixinProps } from "../../theme/mixins/Border";
import Spacing, { SpacingMixinProps } from "../../theme/mixins/Spacing";

interface BoxProps
  extends BackgroundColorMixinProps,
    BorderMixinProps,
    SpacingMixinProps {
  children?: React.ReactNode;
}

const Box = React.forwardRef(({ children, ...rest }: BoxProps, ref) => {
  return (
    // @ts-ignore
    <Container {...rest} ref={ref}>
      {children}
    </Container>
  );
});

const Container = styled("div")<BoxProps>`
  /* flex: 1; */
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  /* max-height: fit-content; */
  transition: background-color 1s ease-in-out;

  margin: 0 auto;

  ${({ theme }) => css`
    background-color: ${theme.palette.bg.B};
    border-radius: ${theme.shape.borderRadius.large}px;
    padding: ${theme.spacing.sizes.xlarge}px;
    /* row-gap: ${theme.spacing.sizes.medium}px; */

    @media screen and (max-width: 768px) {
      margin: 0;
      border-radius: 0;
      height: 100%;
      padding: ${theme.spacing.sizes.large}px;
      /* padding: 0; */
    }
  `}

  ${BackgroundColor};
  ${Spacing};
  ${Border};
`;

export default Box;
