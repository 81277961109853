import styled from "styled-components";
import Border, { BorderMixinProps } from "../../ui/theme/mixins/Border";
import Spacing, { SpacingMixinProps } from "../../ui/theme/mixins/Spacing";

interface ImageContainerProps extends BorderMixinProps, SpacingMixinProps {
  imageUrl: string;
  width?: number;
  height?: number;
  alt?: string;
}

const ImageContainer = ({
  imageUrl,
  width,
  height,
  alt,
  ...rest
}: ImageContainerProps) => {
  return (
    <Container width={width} height={height} {...rest}>
      <StyledImage src={imageUrl} alt={alt} />
    </Container>
  );
};

const Container = styled.div<Omit<ImageContainerProps, "imageUrl">>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  overflow: hidden;
  object-fit: contain;
  padding: ${({ theme }) => theme.spacing.sizes.small}px;
  background-color: ${({ theme }) => theme.colors.greyscale.white + "66"};
  ${Spacing};
  ${Border};
`;

const StyledImage = styled.img<BorderMixinProps>`
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  ${Border};
`;

export default ImageContainer;
