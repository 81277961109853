import React from "react";
import styled, { css } from "styled-components";
import Spacing, { SpacingMixinProps } from "../../theme/mixins/Spacing";
import * as icons from "./icons";
import { IconName } from "./types";

interface IconProps
  extends React.HTMLAttributes<HTMLDivElement>,
    SpacingMixinProps {
  /**ignored if width and height are set */
  size?: number;
  name: IconName;
  width?: number;
  height?: number;
}

const Icon = React.forwardRef(({ size = 48, name, ...rest }: IconProps, ref) => {
  const IconComponent = icons[name];

  const width = rest.width || size;
  const height = rest.height || size;

  return (
    <Container width={width} height={height} {...rest} ref={ref}>
      <IconComponent size={height || width || size} />
    </Container>
  );
});

interface ContainerProps extends SpacingMixinProps {
  width: number;
  height: number;
  ref: any;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `}

  ${({ theme }) => css``}
  ${Spacing};
`;
export default Icon;
