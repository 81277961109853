import React from "react";
import { useNavigate } from "react-router-dom";
import { animated, config, useTransition } from "@react-spring/web";
import { css, styled } from "styled-components";
import DandiInfo from "../../components/DandiInfo";
import Header from "../../components/Header";
import Load from "../../components/Load";
import Question from "../../components/Question";
import { ListProps } from "../../components/Question/Question";
import Sidebar from "../../components/Sidebar";
import { useSmallScreen } from "../../hooks/useSmallScreen";
import { useSurveyProgress } from "../../providers/SurveyProgressProvider";
import { useCustomTheme } from "../../providers/ThemeProvider";
import { IMetaUIElements } from "../../types";
import { Box, Button, Icon, Screen, Spacer, Text } from "../../ui/primitives";
import { PRIVACY_TYPE_COPY } from "../../utils/consts";

interface QuestionsProps {}

const Questions = (props: QuestionsProps) => {
  const ref = React.useRef(null);
  const isSmallScreen = useSmallScreen();
  const { theme } = useCustomTheme();
  const { updateBackground } = useCustomTheme();
  const [currentQuestion, setCurrentQuestion] = React.useState(0);
  const [previousQuestion, setPreviousQuestion] = React.useState(-1);
  const { progress, updateProgress, submitAnswers, canBeSubmitted } =
    useSurveyProgress();
  const [highlightedAnswer, setHighlightedAnswer] = React.useState<number>(-1);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [isTransitioning, setIsTransitioning] = React.useState(false);
  const [isExiting, setIsExiting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [answered, setAnswered] = React.useState(false);
  const [questionOverflow, setQuestionOverflow] = React.useState(false);
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
  const [columnCount, setColumnCount] = React.useState(1);

  const questionContainerRef = React.useRef<HTMLDivElement>(null);
  const contentContainerRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  // @ts-ignore
  let questions = progress.categories.map((c) => c.questions).flat();

  // @ts-ignore
  const questionOrderElem = progress.meta.uiElements.find(
    (u) => u.key === "questionOrder"
  );

  if (questionOrderElem) {
    const questionOrder = JSON.parse(questionOrderElem.value);
    if (questionOrder.length) {
      questions = questionOrder
        .map((qid: string) => {
          return questions.find((q) => q.globalQuestionId === qid);
        })
        .filter((q: any) => !!q);
    }
  }

  const inputType =
    questions[currentQuestion].meta.uiElements.find(
      (q) => q.key === "inputType"
    )?.value ?? "Check";

  const navigate = useNavigate();

  React.useEffect(() => {
    updateBackground();
    setAnswered(false);
  }, [currentQuestion]);

  React.useEffect(() => {
    const resizeWindow = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", resizeWindow);

    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, []);

  React.useEffect(() => {
    if (questionContainerRef.current) {
      setQuestionOverflow(
        questionContainerRef.current.scrollHeight > windowHeight
      );
    }
  }, [
    questionContainerRef.current,
    windowHeight,
    currentQuestion,
    columnCount,
    setQuestionOverflow,
  ]);

  const transitioningOut = useTransition(previousQuestion, {
    from: {
      position: "absolute",
      opacity: 1,
      transform: "translate3d(0,0px,0)",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "stretch",
      width: "100%",
      maxWidth: "1000px",
      padding: theme.spacing.sizes.large,
    },
    enter: {
      position: "absolute",
      opacity: 0,
      transform: "translate3d(0,-100px,0)",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "stretch",
      flexGrow: 1,
      width: "100%",
      maxWidth: "1000px",
      padding: theme.spacing.sizes.large,
    },
    keys: (previousQuestion) => {
      return questions[previousQuestion]?.globalQuestionId || 0;
    },
    onStart: () => setIsTransitioning(true),
    onRest: () => {
      setIsTransitioning(false);
    },
    // delay: 10,
    config: {
      ...config.slow,
      duration: 200,
    },
  });

  const transitioningIn = useTransition(currentQuestion, {
    from: {
      // position: "absolute",
      opacity: 0,
      width: "100%",
      transform: "translate3d(0,100px,0)",
      padding: theme.spacing.sizes.large,
    },
    enter: {
      // position: "relative",
      opacity: 1,
      width: "100%",
      transform: "translate3d(0,0px,0)",
      padding: theme.spacing.sizes.large,
    },
    delay: 500,
    keys: (currentQuestion) => {
      return questions[currentQuestion]?.globalQuestionId || 0;
    },
    onStart: () => setIsTransitioning(true),
    onRest: () => setIsTransitioning(false),
    config: {
      ...config.slow,
      duration: 500,
    },
  });

  const timeoutRef = React.useRef<NodeJS.Timeout | undefined>(undefined);

  const handleChooseAnswer = (
    globalQuestionId: string,
    answer: string,
    score: number
  ) => {
    setAnswered(true);

    // TODO: deep copy is bad in React! fix it using immer
    const newProgress = JSON.parse(JSON.stringify(progress));

    newProgress.categories.forEach((category: any) => {
      category.questions.forEach((question: any) => {
        if (question.globalQuestionId === globalQuestionId) {
          question.eventTimeUtc = new Date().toISOString();
          question.eventTimeLocal = new Date().toISOString();

          question.values.forEach((value: any) => {
            if (value.value === answer) {
              value.score = score;
            }
          });

          const questionType = question.meta.uiElements.find(
            (q: IMetaUIElements) => q.key === "questionType"
          ) ?? { key: "questionType", value: "Single-select" };

          if (questionType.value === "Single-select") {
            // removes score from all other answers
            question.values.forEach((value: any) => {
              if (value.value !== answer) {
                value.score = 0;
              }
            });
          }

          // if the answer is prefer not to answer, remove all other answers
          if (answer === "Prefer not to answer") {
            question.values.forEach((value: any) => {
              if (value.value !== answer) {
                value.score = 0;
              }
            });
          } else {
            const preferNotToAnswer = question.values.find(
              (v: any) => v.value === "Prefer not to answer"
            );

            if (preferNotToAnswer) {
              preferNotToAnswer.score = 0;
            }
          }

          const isQuestionUnanswered = question.values.every((v: any) => {
            return v.score === 0;
          });

          if (
            !isQuestionUnanswered &&
            questionType.value === "Single-select" &&
            currentQuestion !== questions.length - 1
          ) {
            timeoutRef.current = setTimeout(handleNext, 1000);
          }
        }
      });
    });

    updateProgress(newProgress);
  };

  const handleNext = async () => {
    if (currentQuestion === questions.length - 1) {
      await handleSubmit();
    } else {
      setIsExiting(true);
      setPreviousQuestion(currentQuestion);
      setCurrentQuestion((prevState) =>
        prevState < questions.length - 1 ? prevState + 1 : 0
      );
    }
  };

  const handleNextOuter = async () => {
    const question = questions[currentQuestion];
    const questionType = question.meta.uiElements.find(
      (q: IMetaUIElements) => q.key === "questionType"
    ) ?? { key: "questionType", value: "Single-select" };

    if (
      !answered ||
      questionType.value !== "Single-select" ||
      currentQuestion === questions.length - 1
    ) {
      // Make Next for a Single select answered question, not at the end a NoOp
      handleNext();
    }
  };

  const handleSubmit = async () => {
    setIsExiting(true);
    setLoading(true);
    await submitAnswers();
    setLoading(false);
    navigate("/end");
    clearTimeout(timeoutRef.current);
  };

  const handlePrevious = () => {
    setPreviousQuestion(currentQuestion);
    setCurrentQuestion((prevState) =>
      prevState > 0 ? prevState - 1 : questions.length - 1
    );
  };

  const goToQuestion = async (index: number) => {
    setPreviousQuestion(currentQuestion);
    setCurrentQuestion(index);
  };

  React.useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const scrollToTop = () => {
    if (contentContainerRef.current) {
      contentContainerRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }

    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  React.useEffect(() => {
    if (!isTransitioning) {
      scrollToTop();
    }
  }, [isTransitioning]);

  if (!questions || loading) {
    return (
      <Screen bgColor="bg.C" showDandi={false}>
        <Load wink />
      </Screen>
    );
  }

  return (
    <CustomScreen
      bgColor="bg.C"
      ref={containerRef}
      sidebar={
        <Sidebar
          currentQuestion={currentQuestion}
          setCurrentQuestion={goToQuestion}
          questions={questions}
          surveyName={"Self ID"}
          closeSidebar={() => setSidebarOpen(false)}
          submitSurvey={handleSubmit}
        />
      }
      header={<Header showSidebar={() => setSidebarOpen(true)} showHamburger />}
      sidebarOpen={sidebarOpen}
      toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
    >
      <ContentContainer ref={contentContainerRef}>
        {!isSmallScreen && (
          <DandiInfoContainer>
            <DandiInfo />
          </DandiInfoContainer>
        )}
        {/* @ts-ignore */}
        <QuestionsContainer
          bgColor="bg.C"
          columns={3}
          questionOverflow={questionOverflow}
        >
          {transitioningOut(
            (styles, item) =>
              item >= 0 && (
                // @ts-ignore
                <animated.div style={styles} ref={questionContainerRef}>
                  <Question
                    question={questions[item]}
                    setAnswer={handleChooseAnswer}
                    ref={ref}
                    highlightedAnswer={highlightedAnswer}
                    isAnswered={questions[item].values.some(
                      (v: any) => v.score === 1
                    )}
                    noEventListeners={true}
                    columnCount={columnCount}
                    setColumnCount={setColumnCount}
                  />
                  <ButtonContainer>
                    {!isSmallScreen ? (
                      <Button.Secondary
                        borderWidth={1}
                        borderColor="border.A"
                        onClick={handleNextOuter}
                      >
                        {currentQuestion === questions.length - 1
                          ? "Submit"
                          : "Next"}
                      </Button.Secondary>
                    ) : (
                      <MobileActions>
                        <MobileActionsButtons>
                          <Button.Secondary
                            borderWidth={1}
                            borderColor="border.A"
                            onClick={handlePrevious}
                          >
                            Back
                          </Button.Secondary>
                          <Button.Secondary
                            borderWidth={1}
                            borderColor="border.A"
                            style={{ flex: 1 }}
                            onClick={handleNextOuter}
                          >
                            {currentQuestion === questions.length - 1
                              ? "Submit"
                              : "Next"}
                          </Button.Secondary>
                        </MobileActionsButtons>
                        <Spacer size="medium" />
                        <MobileActionsInfoContainer>
                          <Icon
                            name="InfoShield"
                            size={18}
                            marginRight="xsmall"
                          />
                          <Text.CopySmall>
                            {!!progress &&
                              PRIVACY_TYPE_COPY[progress.privacy.type]}
                          </Text.CopySmall>
                        </MobileActionsInfoContainer>
                      </MobileActions>
                    )}
                  </ButtonContainer>
                </animated.div>
              )
          )}
          {transitioningIn((styles, item) => (
            // @ts-ignore
            <animated.div style={styles} ref={questionContainerRef}>
              <Question
                question={questions[item]}
                setAnswer={handleChooseAnswer}
                ref={ref}
                highlightedAnswer={highlightedAnswer}
                isAnswered={questions[item].values.some(
                  (v: any) => v.score === 1
                )}
                columnCount={columnCount}
                setColumnCount={setColumnCount}
              />
              <ButtonContainer>
                {!isSmallScreen ? (
                  <Button.Secondary
                    borderWidth={1}
                    borderColor="border.A"
                    onClick={handleNextOuter}
                  >
                    {currentQuestion === questions.length - 1
                      ? "Submit"
                      : "Next"}
                  </Button.Secondary>
                ) : (
                  <MobileActions>
                    <MobileActionsButtons>
                      <Button.Secondary
                        borderWidth={1}
                        borderColor="border.A"
                        onClick={handlePrevious}
                      >
                        Back
                      </Button.Secondary>
                      <Button.Secondary
                        borderWidth={1}
                        borderColor="border.A"
                        style={{ flex: 1 }}
                        onClick={handleNextOuter}
                      >
                        {currentQuestion === questions.length - 1
                          ? "Submit"
                          : "Next"}
                      </Button.Secondary>
                    </MobileActionsButtons>
                    <Spacer size="medium" />
                    <MobileActionsInfoContainer>
                      <Icon name="InfoShield" size={18} marginRight="xsmall" />
                      <Text.CopySmall>
                        Your answers are{" "}
                        <Text.CopySmall weight="medium">
                          only readable by Dandi
                        </Text.CopySmall>{" "}
                        and they can never be linked to you as an individual by
                        your employer.
                      </Text.CopySmall>
                    </MobileActionsInfoContainer>
                  </MobileActions>
                )}
              </ButtonContainer>
            </animated.div>
          ))}
        </QuestionsContainer>
      </ContentContainer>
    </CustomScreen>
  );
};

const CustomScreen = styled(Screen)`
  position: relative;
`;

const MobileActions = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MobileActionsButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  gap: ${({ theme }) => theme.spacing.sizes.small}px;
`;

const MobileActionsInfoContainer = styled.div`
  display: flex;
  /* flex: 1; */
  justify-content: flex-start;
  align-items: flex-start;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing.sizes.xsmall}px;
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  overflow: auto;
`;

const DandiInfoContainer = styled.div`
  align-self: flex-end;
  ${({ theme }) => css`
    padding-left: ${`${theme.spacing.sizes.xxlarge}px`};
    padding-bottom: ${`${theme.spacing.sizes.xxlarge}px`};
  `}
`;

const QuestionsContainer = styled(Box)<ListProps>`
  height: 100svh;
  position: relative;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex: 1;

  ${({ questionOverflow }) => css`
    justify-content: ${questionOverflow ? "flex-start" : "center"};
  `};

  overflow-y: visible;

  padding: 0px;
  ${({ theme }) => css`
    @media screen and (max-width: 768px) {
      /* padding: ${theme.spacing.sizes.large}px; */
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-bottom: 200px;
    }
  `}

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ErrorStateContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.sizes.small}px;
  border-radius: ${({ theme }) => theme.shape.borderRadius.small}px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: ${({ theme }) => theme.spacing.sizes.small}px;
`;

export default Questions;
