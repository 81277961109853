import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { css, styled } from "styled-components";
import Header from "../components/Header";
import Load from "../components/Load";
import { useSurveyProgress } from "../providers/SurveyProgressProvider";
import { useCustomTheme } from "../providers/ThemeProvider";
import { AuthConfig } from "../types";
import {
  Box,
  Button,
  Icon,
  Input,
  Screen,
  Spacer,
  Text,
} from "../ui/primitives";
import Spacing, { SpacingMixinProps } from "../ui/theme/mixins/Spacing";
import { decodeBase64 } from "../utils/base64";

interface WelcomeProps {}

const Welcome = (props: WelcomeProps) => {
  const {
    employeeId,
    setEmployeeId,
    password,
    setPassword,
    setP1,
    fetchSurvey,
    wrongPassword,
    isValidSurvey,
    loading: surveyLoading,
  } = useSurveyProgress();
  let { encodedSurveyData } = useParams();
  const [config, setConfig] = useState<AuthConfig>({} as AuthConfig);
  const [loading, setLoading] = useState(true);
  const [showWrongPasswordMessage, setShowWrongPasswordMessage] =
    useState(wrongPassword);
  const [showInvalidSurveyMessage, setShowInvalidSurveyMessage] = useState(
    !isValidSurvey
  );
  const { updateBackground } = useCustomTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (encodedSurveyData) {
      const config = JSON.parse(decodeBase64(encodedSurveyData)) as AuthConfig;
      setP1(encodedSurveyData);
      setConfig(config);
      setEmployeeId(config.employeeId ?? "");
    } else {
      window.location.replace("https://itsdandi.com");
    }
  }, [encodedSurveyData]);

  async function handleLogin() {
    setLoading(true);
    await fetchSurvey();
    setLoading(false);
    setShowWrongPasswordMessage(true);
  }

  React.useEffect(() => {
    updateBackground("sky");
    setLoading(false);
  }, []);

  React.useEffect(() => {
    if (
      !wrongPassword &&
      !loading &&
      !surveyLoading &&
      isValidSurvey &&
      !(config.privacy !== "ANONYMOUS" && !password)
    ) {
      setShowWrongPasswordMessage(false);
      navigate("/survey");
    }
  }, [
    wrongPassword,
    loading,
    surveyLoading,
    isValidSurvey,
    config.privacy,
    password,
  ]);

  React.useEffect(() => {
    setShowWrongPasswordMessage(false);
  }, [password]);

  React.useEffect(() => {
    setShowInvalidSurveyMessage(false);
  }, [password, employeeId]);

  React.useEffect(() => {
    if (!isValidSurvey) {
      setShowInvalidSurveyMessage(true);
    }
  }, [isValidSurvey]);

  if (loading || !encodedSurveyData || surveyLoading) {
    return (
      <Screen header={<Header noLogo />} showDandi={false} bgColor='bg.C'>
        <Load wink />
      </Screen>
    );
  }

  if (!isValidSurvey) {
    return (
      <Screen header={<Header noLogo />} showDandi bgColor='bg.C'>
        <Box bgColor='bg.A'>
          <Text.H3>
            This survey is now closed. To learn more, please follow up with your
            HR administrator.
          </Text.H3>
        </Box>
      </Screen>
    );
  }

  return (
    <Screen header={<Header noLogo />} bgColor='bg.C' showDandi>
      <InputContainer bgColor='bg.B'>
        {config.privacy !== "ANONYMOUS" && (
          <Text.Copy marginBottom='medium'>
            Enter {!config.employeeId && "your Employee ID and "}the unique
            password you've received in the email sendout
          </Text.Copy>
        )}
        {config.privacy !== "ANONYMOUS" && !config.employeeId && (
          <Input.TextInput
            defaultValue={config.employeeId ?? ""}
            onChange={(e) => setEmployeeId(e.target.value)}
            value={employeeId}
            placeholder='Employee ID'
            marginBottom='medium'
            autoFocus={!config.employeeId}
          ></Input.TextInput>
        )}
        <Input.TextInput
          // disabled={!!config.password}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type='password'
          placeholder='Password'
          error={showWrongPasswordMessage}
          errorMessage='Sorry, the password you entered is incorrect.'
          autoFocus={
            (!config.employeeId && config.privacy === "ANONYMOUS") ||
            showWrongPasswordMessage
          }
        ></Input.TextInput>
        <Spacer size='large' />
        <CTAContainer>
          <Button.Primary onClick={handleLogin}>Log In</Button.Primary>
        </CTAContainer>
        {/*
{isSmallScreen && (
          <AdditionalInfo
            marginTop="large"
            privacyCopy={PRIVACY_TYPE_COPY[progress.privacy.type]}
          />
        )}
        */}
      </InputContainer>
      {/*
{!isSmallScreen && (
        <AdditionalInfo
          marginTop="xlarge"
          privacyCopy={PRIVACY_TYPE_COPY[progress.privacy.type]}
        />
      )}
      */}
    </Screen>
  );
};

interface AdditionalInfoProps extends SpacingMixinProps {
  privacyCopy?: string;
}

const AdditionalInfo = ({ privacyCopy, ...rest }: AdditionalInfoProps) => {
  return (
    <InfoContainer {...rest}>
      <Icon
        name='InfoShield'
        size={20}
        marginRight='xsmall'
        marginTop='xxsmall'
      />
      <CopyContainer>
        <Text.Copy>{privacyCopy}</Text.Copy>
        <Link
          href='https://itsdandi.com/data-privacy'
          target='_blank'
          rel='noreferrer'
        >
          <Text.CopySmall marginTop='medium'>
            Learn more about Dandi privacy
          </Text.CopySmall>
        </Link>
      </CopyContainer>
    </InfoContainer>
  );
};

const InputContainer = styled(Box)`
  ${({ theme }) => css`
    padding: ${theme.spacing.sizes.xxlarge}px;

    @media screen and (max-width: 768px) {
      padding: ${theme.spacing.sizes.large}px;
    }
  `}
`;

const Link = styled.a`
  text-decoration: underline;
  color: inherit;
`;

const InfoContainer = styled.div<AdditionalInfoProps>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 1000px;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.sizes.xlarge}px;

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  `}

  ${Spacing};
`;

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
`;

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 768px) {
    align-items: stretch;
  }
`;

export default Welcome;
