import React from "react";
import { css, styled } from "styled-components";
import useKeyboardNavigation from "../../screens/survey/useKeyboardNavigation";
import { IQuestion, IRadioButtonValue } from "../../types";
import { Icon, Input, Spacer, Text } from "../../ui/primitives";

interface QuestionProps {
  question: IQuestion;
  setAnswer: (globalQuestionId: string, answer: string, score: number) => void;
  highlightedAnswer?: number;
  isAnswered?: boolean;
  noEventListeners?: boolean;
  columnCount: number;
  setColumnCount: (count: number) => void;
}

const Question = React.forwardRef(
  (
    {
      question,
      setAnswer,
      highlightedAnswer,
      noEventListeners,
      isAnswered,
      columnCount,
      setColumnCount
    }: QuestionProps,
    ref: any
  ) => {
    const [searchValue, setSearchValue] = React.useState("");
    const containerRef = React.useRef<HTMLDivElement>(null);

    const inputType = question.meta.uiElements.find(
      (q) => q.key === "inputType"
    ) ?? { key: "inputType", value: "Check" };

    const questionType = question.meta.uiElements.find(
      (q) => q.key === "questionType"
    ) ?? { key: "questionType", value: "Single-select" };

    const description = question.meta.uiElements.find(
      (q) => q.key === "description"
    ) ?? { key: "description", value: "" };

    const answerDescs = JSON.parse(
      question.meta.uiElements.find((u) => u.key === "answerDescs")?.value ??
        "[]"
    );
    const answerCount = question.values.length;

    const {} = useKeyboardNavigation({
      ref,
      trigger: null,
      disabled: inputType.value === "Search" || !!noEventListeners,
    });

    React.useEffect(() => {
      if (!ref?.current) return;
      ref.current.focus();
    }, []);

    React.useEffect(() => {
      if (answerCount > 16) {
        setColumnCount(3);
      } else if (answerCount > 8) {
        setColumnCount(2);
      } else {
        setColumnCount(1);
      }
    }, [answerCount]);

    const preferNotToAnswer = question.values.find(
      (v) => v.value === "Prefer not to answer"
    );

    if (!question.values) return null;

    const answers = question.values.map((v) => {
      const desc = answerDescs.find((a: any) => a.value === v.value)?.desc;
      return {
        value: v.value,
        score: v.score,
        desc,
      };
    });

    const handleClickSearchResult = (value: string) => {
      setSearchValue("");
      setAnswer(question.globalQuestionId, value, 1.0);
    };

    const handleClick = (answer: IRadioButtonValue) => {
      const newScore = answer.score === 0 ? 1.0 : 0.0;
      setAnswer(question.globalQuestionId, answer.value, newScore);
    };

    const handleDelete = (index: number) => {
      setAnswer(question.globalQuestionId, answers[index].value, 0.0);
    };

    return (
      <Container tabIndex={-1} ref={containerRef}>
        <Headline weight="regular">{question.title}</Headline>
        {description.value && (
          <>
            <Text.Copy>{description.value}</Text.Copy>
            <Spacer size="xxsmall" />
          </>
        )}
        <List
          tabIndex={-1}
          ref={ref}
          className="questions-list"
          columns={columnCount}
          ignoreColumns={
            inputType.value !== "Check" && inputType.value !== "Radio"
          }
        >
          {(inputType.value === "Check" || inputType.value === "Radio") &&
            answers.map((value, index) => {
              return (
                <Input.MultipleChoice
                  key={index}
                  tabIndex={index === 0 ? 0 : -1}
                  onClick={() => {
                    handleClick(value);
                  }}
                  selected={value.score === 1.0}
                  highlighted={highlightedAnswer === index}
                  desc={value.desc}
                >
                  {value.value}
                </Input.MultipleChoice>
              );
            })}
          {inputType.value === "Search" && (
            <>
              {answers.map((value, index) => {
                if (
                  value.score === 1.0 &&
                  value.value !== "Prefer not to answer"
                )
                  return (
                    <Input.AnsweredSearch
                      key={index}
                      tabIndex={0}
                      onDelete={() => handleDelete(index)}
                    >
                      {value.value}
                    </Input.AnsweredSearch>
                  );
              })}
              <Input.SearchInput
                options={answers
                  .filter((v) => v.value !== "Prefer not to answer")
                  .filter((v) => v.score < 1)
                  .map((value) => value.value)}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onClickResult={handleClickSearchResult}
                placeholder="Start typing to search..."
              />
              {preferNotToAnswer && (
                <Input.MultipleChoice
                  tabIndex={0}
                  onClick={() => {
                    handleClick(preferNotToAnswer);
                  }}
                  selected={preferNotToAnswer.score === 1.0}
                  highlighted={highlightedAnswer === answers.length}
                >
                  {preferNotToAnswer.value}
                </Input.MultipleChoice>
              )}
            </>
          )}
        </List>
      </Container>
    );
  }
);

Question.displayName = "Question";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({ theme }) => css`
    row-gap: ${theme.spacing.sizes.xsmall}px;
    margin-bottom: ${theme.spacing.sizes.xlarge}px;
  `}
`;

const Headline = styled(Text.H2)`
  margin-bottom: ${({ theme }) => theme.spacing.sizes.medium}px;
`;

export interface ListProps {
  columns: number;
  ignoreColumns?: boolean;
  questionOverflow?: boolean;
}

const List = styled.ul<ListProps>`
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 768px) {
    ${({ columns, ignoreColumns }) =>
      !ignoreColumns &&
      css`
        grid-template-columns: repeat(${columns}, 1fr);
      `}
  }
  overflow: visible;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  gap: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -1rem;
  margin-bottom: 0rem;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: none !important;
  }
`;

const ListItem = styled.li`
  list-style: none;
  display: flex;
  /* flex: 1; */
  flex-grow: 1;
`;

export default Question;
