import * as consts from "./consts";

type SupportedKeys = keyof typeof consts;

export const save = async (key: SupportedKeys, data: any) => {
  if (key) {
    await localStorage.setItem(key, JSON.stringify(data));
  }
};

export const load = async (key: SupportedKeys) => {
  const loadedString = await localStorage.getItem(key);
  if (loadedString) {
    return JSON.parse(loadedString);
  }
  return "";
};
