import React from "react";
import { css, styled } from "styled-components";
import { useDebounce } from "../../../../hooks/useDebounce";
import TextInput from "../TextInput";
import useSearchInputKeyboardNavigation from "./useSearchInputKeyboardNavigation";

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  options: string[];
  onClickResult?: (result: string) => void;
}

const SearchInput = ({ options, onClickResult, ...rest }: SearchInputProps) => {
  const debouncedValue = useDebounce(rest.value, 300);
  const [isFocused, setIsFocused] = React.useState(false);
  const [dropdownShown, setDropdownShown] = React.useState(false);

  const filteredOptions = options.filter((o) =>
    o
      .toLowerCase()
      .trim()
      .includes(debouncedValue?.toString().trim().toLowerCase() || "")
  );

  React.useEffect(() => {
    let timeout: any;
    if (isFocused) {
      setDropdownShown(true);
    } else {
      setTimeout(() => {
        setDropdownShown(false);
      }, 150);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isFocused]);

  return (
    <Container tabIndex={-1}>
      <TextInput
        onFocus={(e: any) => {
          e.stopPropagation();
          setIsFocused(true);
        }}
        onBlur={(e: any) => {
          e.stopPropagation();
          setIsFocused(false);
        }}
        {...rest}
      />
      {dropdownShown && (
        <Dropdown
          options={filteredOptions}
          onClick={onClickResult}
          dropdownShown={setDropdownShown}
        />
      )}
    </Container>
  );
};

const Dropdown = ({
  options,
  onClick,
  dropdownShown,
}: {
  options: string[];
  onClick?: (result: string) => void;
  dropdownShown: (shown: boolean) => void;
}) => {
  const ref = React.useRef(null);

  const {} = useSearchInputKeyboardNavigation(ref, options.length);

  return (
    <ResultsContainer tabIndex={-1} ref={ref}>
      {options.map((option, index) => (
        <Result
          role='button'
          key={index}
          tabIndex={index === 0 ? 0 : -1}
          onClick={(e) => {
            e.stopPropagation();
            onClick && onClick(option);
            dropdownShown(false);
          }}
          last={index === options.length - 1}
        >
          {option}
        </Result>
      ))}
    </ResultsContainer>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: none;
  }
`;

const ResultsContainer = styled.div`
  position: absolute;
  top: 100%;
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
  z-index: 1;

  ${({ theme }) => css`
    background-color: ${theme.palette.bg.A};
    margin-top: 8px;
    border-radius: ${theme.shape.borderRadius.small}px;
  `}
`;

const Result = styled.div<{ last?: boolean }>`
  cursor: pointer;
  ${({ theme, last }) => css`
    padding: ${theme.spacing.sizes.small}px;
    border-bottom: ${last
      ? "none"
      : "1px solid" + theme.palette.border.A + "1A"};

    &:focus,
    &:focus-within,
    &:focus-visible {
      outline: none;
    }

    &:focus-visible,
    &:hover {
      background-color: ${theme.palette.bg.B};
    }
  `}
`;

export default SearchInput;
