import { css, DefaultTheme } from "styled-components";

import { SizeType } from "../types";
import { getSpacingInPixels } from "../utils";

export interface SpacingMixinProps {
  marginHorizontal?: number | SizeType;
  marginVertical?: number | SizeType;
  marginTop?: number | SizeType;
  marginLeft?: number | SizeType;
  marginBottom?: number | SizeType;
  marginRight?: number | SizeType;
  paddingHorizontal?: number | SizeType;
  paddingVertical?: number | SizeType;
  paddingTop?: number | SizeType;
  paddingLeft?: number | SizeType;
  paddingBottom?: number | SizeType;
  paddingRight?: number | SizeType;
}

function mapPropsToStyles(props: SpacingMixinProps, theme: DefaultTheme) {
  return Object.entries(props)
    .filter((p) => p[0].match(/margin|padding/))
    .map(
      (p) =>
        `${p[0]
          .split(/(?=[A-Z])/)
          .map((v) => v.toLowerCase())
          .join("-")}: ${getSpacingInPixels(p[1], theme)}px`
    )
    .join(";\n");
}

export default css<SpacingMixinProps>`
  ${(props) => mapPropsToStyles(props, props.theme)};
`;
