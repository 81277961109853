import * as colors from "./colors";
import { IPalette } from "./types";

export const palette: IPalette = {
  light: {
    bg: {
      A: colors.sky[0],
      B: colors.sky[100],
      C: colors.sky[300],
      D: colors.sky[400],
      E: colors.sky[500],
      F: colors.sky[600],
      G: colors.sky[700],
      H: colors.sky[800],
      I: colors.sky[900],
      J: colors.sky[1000],
      brand: {
        A: colors.skyBlue[400],
        B: colors.skyBlue[500],
        C: colors.skyBlue[300],
        D: colors.skyBlue[200],
        E: colors.skyBlue[100],
        F: colors.skyBlue[50],
        G: colors.skyBlue[600],
        H: colors.skyBlue[700],
        I: colors.skyBlue[800],
        J: colors.skyBlue[900],
      },
      success: {
        A: colors.springGreen[200],
        B: colors.springGreen[300],
        C: colors.springGreen[100],
        D: colors.springGreen[50],
        E: colors.springGreen[400],
        F: colors.springGreen[500],
        G: colors.springGreen[600],
        H: colors.springGreen[700],
        I: colors.springGreen[800],
        J: colors.springGreen[900],
      },
      warning: {
        A: colors.mangoTango[200],
        B: colors.mangoTango[300],
        C: colors.mangoTango[100],
        D: colors.mangoTango[50],
        E: colors.mangoTango[400],
        F: colors.mangoTango[500],
        G: colors.mangoTango[600],
        H: colors.mangoTango[700],
        I: colors.mangoTango[800],
        J: colors.mangoTango[900],
      },
      danger: {
        A: colors.razzmataz[300],
        B: colors.razzmataz[400],
        C: colors.razzmataz[200],
        D: colors.razzmataz[100],
        E: colors.razzmataz[50],
        F: colors.razzmataz[500],
        G: colors.razzmataz[600],
        H: colors.razzmataz[700],
        I: colors.razzmataz[800],
        J: colors.razzmataz[900],
      },
      disabled: {
        A: colors.greyscale[300],
        B: colors.greyscale[400],
        C: colors.greyscale[200],
        D: colors.greyscale[100],
        E: colors.greyscale[50],
        F: colors.greyscale[500],
        G: colors.greyscale[600],
        H: colors.greyscale[700],
        I: colors.greyscale[800],
        J: colors.greyscale[900],
      },
      highlight: {
        A: colors.greyscale[900],
        B: colors.greyscale[1000],
        C: colors.greyscale[800],
        D: colors.greyscale[700],
        E: colors.greyscale[600],
        F: colors.greyscale[500],
        G: colors.greyscale[400],
        H: colors.greyscale[200],
        I: colors.greyscale[100],
        J: colors.greyscale.white,
      },
    },
    text: {
      A: colors.greyscale.black,
      B: colors.gunmetal[900],
      C: colors.gunmetal[800],
      D: colors.gunmetal[700],
      E: colors.gunmetal[600],
      F: colors.gunmetal[500],
      G: colors.gunmetal[400],
      H: colors.gunmetal[300],
      I: colors.gunmetal[200],
      J: colors.greyscale.white,
      brand: {
        A: colors.skyBlue[400],
        B: colors.skyBlue[500],
        C: colors.skyBlue[300],
        D: colors.skyBlue[200],
        E: colors.skyBlue[100],
        F: colors.skyBlue[50],
        G: colors.skyBlue[600],
        H: colors.skyBlue[700],
        I: colors.skyBlue[800],
        J: colors.skyBlue[900],
      },
      success: {
        A: colors.springGreen[200],
        B: colors.springGreen[300],
        C: colors.springGreen[100],
        D: colors.springGreen[50],
        E: colors.springGreen[400],
        F: colors.springGreen[500],
        G: colors.springGreen[600],
        H: colors.springGreen[700],
        I: colors.springGreen[800],
        J: colors.springGreen[900],
      },
      warning: {
        A: colors.mangoTango[200],
        B: colors.mangoTango[300],
        C: colors.mangoTango[100],
        D: colors.mangoTango[50],
        E: colors.mangoTango[400],
        F: colors.mangoTango[500],
        G: colors.mangoTango[600],
        H: colors.mangoTango[700],
        I: colors.mangoTango[800],
        J: colors.mangoTango[900],
      },
      danger: {
        A: colors.razzmataz[300],
        B: colors.razzmataz[400],
        C: colors.razzmataz[200],
        D: colors.razzmataz[100],
        E: colors.razzmataz[50],
        F: colors.razzmataz[500],
        G: colors.razzmataz[600],
        H: colors.razzmataz[700],
        I: colors.razzmataz[800],
        J: colors.razzmataz[900],
      },
      disabled: {
        A: colors.greyscale[300],
        B: colors.greyscale[400],
        C: colors.greyscale[200],
        D: colors.greyscale[100],
        E: colors.greyscale[50],
        F: colors.greyscale[500],
        G: colors.greyscale[600],
        H: colors.greyscale[700],
        I: colors.greyscale[800],
        J: colors.greyscale[900],
      },
      highlight: {
        A: colors.gunmetal[900],
        B: colors.gunmetal[1000],
        C: colors.gunmetal[800],
        D: colors.gunmetal[700],
        E: colors.gunmetal[600],
        F: colors.gunmetal[500],
        G: colors.gunmetal[400],
        H: colors.gunmetal[300],
        I: colors.gunmetal[200],
        J: colors.gunmetal[100],
      },
      onBrand: colors.gunmetal[900],
      onDisabled: colors.greyscale[600],
      onDanger: colors.greyscale.white,
      onHighlight: colors.greyscale.white,
      onSuccess: colors.gunmetal[900],
      onWarning: colors.greyscale.white,
    },
    icon: {
      A: colors.greyscale.black,
      B: colors.gunmetal[900],
      C: colors.gunmetal[800],
      D: colors.gunmetal[700],
      E: colors.gunmetal[600],
      F: colors.gunmetal[500],
      G: colors.gunmetal[400],
      H: colors.gunmetal[300],
      I: colors.gunmetal[200],
      J: colors.gunmetal[100],
      brand: {
        A: colors.skyBlue[400],
        B: colors.skyBlue[500],
        C: colors.skyBlue[300],
        D: colors.skyBlue[200],
        E: colors.skyBlue[100],
        F: colors.skyBlue[50],
        G: colors.skyBlue[600],
        H: colors.skyBlue[700],
        I: colors.skyBlue[800],
        J: colors.skyBlue[900],
      },
      success: {
        A: colors.springGreen[200],
        B: colors.springGreen[300],
        C: colors.springGreen[100],
        D: colors.springGreen[50],
        E: colors.springGreen[400],
        F: colors.springGreen[500],
        G: colors.springGreen[600],
        H: colors.springGreen[700],
        I: colors.springGreen[800],
        J: colors.springGreen[900],
      },
      warning: {
        A: colors.mangoTango[200],
        B: colors.mangoTango[300],
        C: colors.mangoTango[100],
        D: colors.mangoTango[50],
        E: colors.mangoTango[400],
        F: colors.mangoTango[500],
        G: colors.mangoTango[600],
        H: colors.mangoTango[700],
        I: colors.mangoTango[800],
        J: colors.mangoTango[900],
      },
      danger: {
        A: colors.razzmataz[300],
        B: colors.razzmataz[400],
        C: colors.razzmataz[200],
        D: colors.razzmataz[100],
        E: colors.razzmataz[50],
        F: colors.razzmataz[500],
        G: colors.razzmataz[600],
        H: colors.razzmataz[700],
        I: colors.razzmataz[800],
        J: colors.razzmataz[900],
      },
      disabled: {
        A: colors.greyscale[300],
        B: colors.greyscale[400],
        C: colors.greyscale[200],
        D: colors.greyscale[100],
        E: colors.greyscale[50],
        F: colors.greyscale[500],
        G: colors.greyscale[600],
        H: colors.greyscale[700],
        I: colors.greyscale[800],
        J: colors.greyscale[900],
      },
      highlight: {
        A: colors.gunmetal[900],
        B: colors.gunmetal[1000],
        C: colors.gunmetal[800],
        D: colors.gunmetal[700],
        E: colors.gunmetal[600],
        F: colors.gunmetal[500],
        G: colors.gunmetal[400],
        H: colors.gunmetal[300],
        I: colors.gunmetal[200],
        J: colors.gunmetal[100],
      },
      onBrand: colors.gunmetal[900],
      onDisabled: colors.greyscale[600],
      onDanger: colors.greyscale.white,
      onHighlight: colors.greyscale.white,
      onSuccess: colors.gunmetal[900],
      onWarning: colors.greyscale.white,
    },
    border: {
      A: colors.greyscale.black,
      B: colors.gunmetal[900],
      C: colors.gunmetal[800],
      D: colors.gunmetal[700],
      E: colors.gunmetal[600],
      F: colors.gunmetal[500],
      G: colors.gunmetal[400],
      H: colors.gunmetal[300],
      I: colors.gunmetal[200],
      J: colors.gunmetal[100],
      brand: {
        A: colors.skyBlue[400],
        B: colors.skyBlue[500],
        C: colors.skyBlue[300],
        D: colors.skyBlue[200],
        E: colors.skyBlue[100],
        F: colors.skyBlue[50],
        G: colors.skyBlue[600],
        H: colors.skyBlue[700],
        I: colors.skyBlue[800],
        J: colors.skyBlue[900],
      },
      success: {
        A: colors.springGreen[200],
        B: colors.springGreen[300],
        C: colors.springGreen[100],
        D: colors.springGreen[50],
        E: colors.springGreen[400],
        F: colors.springGreen[500],
        G: colors.springGreen[600],
        H: colors.springGreen[700],
        I: colors.springGreen[800],
        J: colors.springGreen[900],
      },
      warning: {
        A: colors.mangoTango[200],
        B: colors.mangoTango[300],
        C: colors.mangoTango[100],
        D: colors.mangoTango[50],
        E: colors.mangoTango[400],
        F: colors.mangoTango[500],
        G: colors.mangoTango[600],
        H: colors.mangoTango[700],
        I: colors.mangoTango[800],
        J: colors.mangoTango[900],
      },
      danger: {
        A: colors.razzmataz[300],
        B: colors.razzmataz[400],
        C: colors.razzmataz[200],
        D: colors.razzmataz[100],
        E: colors.razzmataz[50],
        F: colors.razzmataz[500],
        G: colors.razzmataz[600],
        H: colors.razzmataz[700],
        I: colors.razzmataz[800],
        J: colors.razzmataz[900],
      },
      disabled: {
        A: colors.greyscale[300],
        B: colors.greyscale[400],
        C: colors.greyscale[200],
        D: colors.greyscale[100],
        E: colors.greyscale[50],
        F: colors.greyscale[500],
        G: colors.greyscale[600],
        H: colors.greyscale[700],
        I: colors.greyscale[800],
        J: colors.greyscale[900],
      },
      highlight: {
        A: colors.gunmetal[900],
        B: colors.gunmetal[1000],
        C: colors.gunmetal[800],
        D: colors.gunmetal[700],
        E: colors.gunmetal[600],
        F: colors.gunmetal[500],
        G: colors.gunmetal[400],
        H: colors.gunmetal[300],
        I: colors.gunmetal[200],
        J: colors.gunmetal[100],
      },
      onBrand: colors.gunmetal[900],
      onDisabled: colors.greyscale[600],
      onDanger: colors.greyscale.white,
      onHighlight: colors.greyscale.white,
      onSuccess: colors.gunmetal[900],
      onWarning: colors.greyscale.white,
    },
  },
  dark: {
    bg: {
      A: colors.sky[800],
      B: colors.sky[900],
      C: colors.sky[1000],
      D: colors.sky[700],
      E: colors.sky[600],
      F: colors.sky[500],
      G: colors.sky[400],
      H: colors.sky[300],
      I: colors.sky[200],
      J: colors.sky[100],
      brand: {
        A: colors.skyBlue[400],
        B: colors.skyBlue[300],
        C: colors.skyBlue[500],
        D: colors.skyBlue[600],
        E: colors.skyBlue[700],
        F: colors.skyBlue[800],
        G: colors.skyBlue[900],
        H: colors.skyBlue[1000],
        I: colors.skyBlue[200],
        J: colors.skyBlue[100],
      },
      success: {
        A: colors.springGreen[200],
        B: colors.springGreen[100],
        C: colors.springGreen[300],
        D: colors.springGreen[400],
        E: colors.springGreen[500],
        F: colors.springGreen[600],
        G: colors.springGreen[700],
        H: colors.springGreen[800],
        I: colors.springGreen[900],
        J: colors.springGreen[1000],
      },
      warning: {
        A: colors.mangoTango[200],
        B: colors.mangoTango[100],
        C: colors.mangoTango[300],
        D: colors.mangoTango[400],
        E: colors.mangoTango[500],
        F: colors.mangoTango[600],
        G: colors.mangoTango[700],
        H: colors.mangoTango[800],
        I: colors.mangoTango[900],
        J: colors.mangoTango[1000],
      },
      danger: {
        A: colors.razzmataz[300],
        B: colors.razzmataz[200],
        C: colors.razzmataz[400],
        D: colors.razzmataz[500],
        E: colors.razzmataz[600],
        F: colors.razzmataz[700],
        G: colors.razzmataz[800],
        H: colors.razzmataz[900],
        I: colors.razzmataz[1000],
        J: colors.razzmataz[100],
      },
      disabled: {
        A: colors.greyscale[300],
        B: colors.greyscale[200],
        C: colors.greyscale[400],
        D: colors.greyscale[500],
        E: colors.greyscale[600],
        F: colors.greyscale[700],
        G: colors.greyscale[800],
        H: colors.greyscale[900],
        I: colors.greyscale[1000],
        J: colors.greyscale[200],
      },
      highlight: {
        J: colors.greyscale[600],
        I: colors.greyscale[800],
        H: colors.greyscale[1000],
        G: colors.greyscale[700],
        F: colors.greyscale[600],
        E: colors.greyscale[500],
        D: colors.greyscale[400],
        C: colors.greyscale[300],
        B: colors.greyscale[200],
        A: colors.greyscale[100],
      },
    },
    text: {
      A: colors.greyscale.white,
      B: colors.greyscale[50],
      C: colors.greyscale[100],
      D: colors.greyscale[200],
      E: colors.greyscale[300],
      F: colors.greyscale[400],
      G: colors.greyscale[500],
      H: colors.greyscale[600],
      I: colors.greyscale[700],
      J: colors.greyscale[800],
      brand: {
        A: colors.skyBlue[400],
        B: colors.skyBlue[300],
        C: colors.skyBlue[500],
        D: colors.skyBlue[600],
        E: colors.skyBlue[700],
        F: colors.skyBlue[800],
        G: colors.skyBlue[900],
        H: colors.skyBlue[1000],
        I: colors.skyBlue[200],
        J: colors.skyBlue[100],
      },
      success: {
        A: colors.springGreen[200],
        B: colors.springGreen[100],
        C: colors.springGreen[300],
        D: colors.springGreen[400],
        E: colors.springGreen[500],
        F: colors.springGreen[600],
        G: colors.springGreen[700],
        H: colors.springGreen[800],
        I: colors.springGreen[900],
        J: colors.springGreen[1000],
      },
      warning: {
        A: colors.mangoTango[200],
        B: colors.mangoTango[100],
        C: colors.mangoTango[300],
        D: colors.mangoTango[400],
        E: colors.mangoTango[500],
        F: colors.mangoTango[600],
        G: colors.mangoTango[700],
        H: colors.mangoTango[800],
        I: colors.mangoTango[900],
        J: colors.mangoTango[1000],
      },
      danger: {
        A: colors.razzmataz[300],
        B: colors.razzmataz[200],
        C: colors.razzmataz[400],
        D: colors.razzmataz[500],
        E: colors.razzmataz[600],
        F: colors.razzmataz[700],
        G: colors.razzmataz[800],
        H: colors.razzmataz[900],
        I: colors.razzmataz[1000],
        J: colors.razzmataz[100],
      },
      disabled: {
        A: colors.greyscale[300],
        B: colors.greyscale[200],
        C: colors.greyscale[400],
        D: colors.greyscale[500],
        E: colors.greyscale[600],
        F: colors.greyscale[700],
        G: colors.greyscale[800],
        H: colors.greyscale[900],
        I: colors.greyscale[1000],
        J: colors.greyscale[200],
      },
      highlight: {
        J: colors.gunmetal[900],
        I: colors.gunmetal[800],
        H: colors.gunmetal[1000],
        G: colors.gunmetal[700],
        F: colors.gunmetal[600],
        E: colors.gunmetal[500],
        D: colors.gunmetal[400],
        C: colors.gunmetal[300],
        B: colors.gunmetal[200],
        A: colors.gunmetal[100],
      },
      onBrand: colors.gunmetal[900],
      onDisabled: colors.greyscale[600],
      onDanger: colors.greyscale.white,
      onHighlight: colors.greyscale.white,
      onSuccess: colors.gunmetal[900],
      onWarning: colors.greyscale.white,
    },
    icon: {
      A: colors.greyscale.white,
      B: colors.greyscale[50],
      C: colors.greyscale[100],
      D: colors.greyscale[200],
      E: colors.greyscale[300],
      F: colors.greyscale[400],
      G: colors.greyscale[500],
      H: colors.greyscale[600],
      I: colors.greyscale[700],
      J: colors.greyscale[800],
      brand: {
        A: colors.skyBlue[400],
        B: colors.skyBlue[300],
        C: colors.skyBlue[500],
        D: colors.skyBlue[600],
        E: colors.skyBlue[700],
        F: colors.skyBlue[800],
        G: colors.skyBlue[900],
        H: colors.skyBlue[1000],
        I: colors.skyBlue[200],
        J: colors.skyBlue[100],
      },
      success: {
        A: colors.springGreen[200],
        B: colors.springGreen[100],
        C: colors.springGreen[300],
        D: colors.springGreen[400],
        E: colors.springGreen[500],
        F: colors.springGreen[600],
        G: colors.springGreen[700],
        H: colors.springGreen[800],
        I: colors.springGreen[900],
        J: colors.springGreen[1000],
      },
      warning: {
        A: colors.mangoTango[200],
        B: colors.mangoTango[100],
        C: colors.mangoTango[300],
        D: colors.mangoTango[400],
        E: colors.mangoTango[500],
        F: colors.mangoTango[600],
        G: colors.mangoTango[700],
        H: colors.mangoTango[800],
        I: colors.mangoTango[900],
        J: colors.mangoTango[1000],
      },
      danger: {
        A: colors.razzmataz[300],
        B: colors.razzmataz[200],
        C: colors.razzmataz[400],
        D: colors.razzmataz[500],
        E: colors.razzmataz[600],
        F: colors.razzmataz[700],
        G: colors.razzmataz[800],
        H: colors.razzmataz[900],
        I: colors.razzmataz[1000],
        J: colors.razzmataz[100],
      },
      disabled: {
        A: colors.greyscale[300],
        B: colors.greyscale[200],
        C: colors.greyscale[400],
        D: colors.greyscale[500],
        E: colors.greyscale[600],
        F: colors.greyscale[700],
        G: colors.greyscale[800],
        H: colors.greyscale[900],
        I: colors.greyscale[1000],
        J: colors.greyscale[200],
      },
      highlight: {
        A: colors.gunmetal[900],
        B: colors.gunmetal[800],
        C: colors.gunmetal[1000],
        D: colors.gunmetal[700],
        E: colors.gunmetal[600],
        F: colors.gunmetal[500],
        G: colors.gunmetal[400],
        H: colors.gunmetal[300],
        I: colors.gunmetal[200],
        J: colors.gunmetal[100],
      },
      onBrand: colors.gunmetal[900],
      onDisabled: colors.greyscale[600],
      onDanger: colors.greyscale.white,
      onHighlight: colors.greyscale.white,
      onSuccess: colors.gunmetal[900],
      onWarning: colors.greyscale.white,
    },
    border: {
      A: colors.greyscale.white,
      B: colors.greyscale[50],
      C: colors.greyscale[100],
      D: colors.greyscale[200],
      E: colors.greyscale[300],
      F: colors.greyscale[400],
      G: colors.greyscale[500],
      H: colors.greyscale[600],
      I: colors.greyscale[700],
      J: colors.greyscale[800],
      brand: {
        A: colors.skyBlue[400],
        B: colors.skyBlue[300],
        C: colors.skyBlue[500],
        D: colors.skyBlue[600],
        E: colors.skyBlue[700],
        F: colors.skyBlue[800],
        G: colors.skyBlue[900],
        H: colors.skyBlue[1000],
        I: colors.skyBlue[200],
        J: colors.skyBlue[100],
      },
      success: {
        A: colors.springGreen[200],
        B: colors.springGreen[100],
        C: colors.springGreen[300],
        D: colors.springGreen[400],
        E: colors.springGreen[500],
        F: colors.springGreen[600],
        G: colors.springGreen[700],
        H: colors.springGreen[800],
        I: colors.springGreen[900],
        J: colors.springGreen[1000],
      },
      warning: {
        A: colors.mangoTango[200],
        B: colors.mangoTango[100],
        C: colors.mangoTango[300],
        D: colors.mangoTango[400],
        E: colors.mangoTango[500],
        F: colors.mangoTango[600],
        G: colors.mangoTango[700],
        H: colors.mangoTango[800],
        I: colors.mangoTango[900],
        J: colors.mangoTango[1000],
      },
      danger: {
        A: colors.razzmataz[300],
        B: colors.razzmataz[200],
        C: colors.razzmataz[400],
        D: colors.razzmataz[500],
        E: colors.razzmataz[600],
        F: colors.razzmataz[700],
        G: colors.razzmataz[800],
        H: colors.razzmataz[900],
        I: colors.razzmataz[1000],
        J: colors.razzmataz[100],
      },
      disabled: {
        A: colors.greyscale[300],
        B: colors.greyscale[200],
        C: colors.greyscale[400],
        D: colors.greyscale[500],
        E: colors.greyscale[600],
        F: colors.greyscale[700],
        G: colors.greyscale[800],
        H: colors.greyscale[900],
        I: colors.greyscale[1000],
        J: colors.greyscale[200],
      },
      highlight: {
        J: colors.gunmetal[900],
        I: colors.gunmetal[800],
        H: colors.gunmetal[1000],
        G: colors.gunmetal[700],
        F: colors.gunmetal[600],
        E: colors.gunmetal[500],
        D: colors.gunmetal[400],
        C: colors.gunmetal[300],
        B: colors.gunmetal[200],
        A: colors.gunmetal[100],
      },
      onBrand: colors.gunmetal[900],
      onDisabled: colors.greyscale[600],
      onDanger: colors.greyscale.white,
      onHighlight: colors.greyscale.white,
      onSuccess: colors.gunmetal[900],
      onWarning: colors.greyscale.white,
    },
  },
};
