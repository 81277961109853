import { PrivacyTypeCopy } from "../types";

// STORAGE
export const PREFERRED_THEME_VERSION = "PREFERRED_THEME_VERSION";
export const PREFERRED_LIGHT_THEME = "PREFERRED_LIGHT_THEME";
export const PREFERRED_DARK_THEME = "PREFERRED_DARK_THEME";

export const CURRENT_PROGRESS = "CURRENT_PROGRESS";
export const ENCRYPTED_PATH = "ENCRYPTED_PATH";

// COPY
export const PRIVACY_TYPE_COPY: PrivacyTypeCopy = {
  ANONYMOUS:
    "Your responses are completely anonymous and cannot be linked to you as an individual.",
  DANDI_ONLY:
    "Your responses are only stored within Dandi. Your employer cannot link your responses to you as an individual.",
  DANDI_CUSTOMER:
    "Your responses are only accessible by Dandi and your employer.",
};
