import React from "react";
import { css, styled } from "styled-components";
import Icon from "../../Icon";
import Text from "../../Text";
import * as Tooltip from "@radix-ui/react-tooltip";
import { TooltipContent } from "../../Tooltip";

interface MultipleChoiceProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  title?: string;
  selected?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  desc?: string;
}

const MultipleChoice = React.forwardRef(
  (
    {
      children,
      title,
      selected: selectedProp,
      highlighted,
      disabled,
      desc,
      ...rest
    }: MultipleChoiceProps,
    ref
  ) => {
    const [selected, setSelected] = React.useState(selectedProp || false);

    React.useEffect(() => {
      setSelected(selectedProp || false);
    }, [selectedProp]);

    return (
      <Container
        role="button"
        selected={selected}
        highlighted={highlighted}
        disabled={disabled}
        {...rest}
        onClick={(e) => {
          if (disabled) return;
          rest.onClick && rest.onClick(e);
          setSelected(!selected);
        }}
        // @ts-ignore
        ref={ref}
      >
        <Stack>
          <Text.Copy weight={selected ? "medium" : "regular"}>
            {children || title}
          </Text.Copy>
          <Tooltip.Provider>
            <Tooltip.Root>
              {desc && (
                <Tooltip.Trigger asChild>
                  <Icon
                    name="Info"
                    size={18}
                    marginLeft={0.5}
                  />
                </Tooltip.Trigger>
              )}
              <Tooltip.Portal>
                <TooltipContent sideOffset={16} side="bottom">
                  <div
                    style={{ maxHeight: 400, overflowY: 'auto' }}
                    dangerouslySetInnerHTML={{
                      __html: desc?.replace(/\n/g, "<br />") ?? "",
                    }}
                  />
                </TooltipContent>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        </Stack>
        {selected && <Icon name="Checkbox" size={18} />}
      </Container>
    );
  }
);

const Stack = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.button<MultipleChoiceProps>`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: auto;
  max-width: 100%;

  ${({ theme, selected, highlighted, disabled }) => css`
    position: relative;
    background-color: ${
      selected ? theme.palette.bg.A : theme.palette.bg.A + "80"
    };
    padding: ${theme.spacing.sizes.medium}px;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    border-radius: ${theme.shape.borderRadius.small}px;
    animation: ${selected ? "glowing 400ms 2 ease-in-out" : "none"};
    
    box-shadow: ${
      selected
        ? "rgba(0, 0, 0, 0.1) 0px 10px 30px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"
        : "none"
    };

    

    &:hover {
      background-color: ${
        disabled ? theme.palette.bg.A + "80" : theme.palette.bg.A + "BF"
      };

      @media screen and (max-width: 768px) {
        background-color: ${
          selected ? theme.palette.bg.A : theme.palette.bg.A + 80
        };
      }
    }
    &:active {
      background-color: ${theme.palette.bg.B};
      box-shadow: 0 0 10px ${theme.palette.bg.D};
    }

    &:focus, &:focus-visible, &:focus-within {
      outline: none;
    }

    &:focus-visible {
      &::after {
        box-shadow: 0 0 0 2px ${theme.palette.border.D};
      }
    }

    }
  `};
`;

export default MultipleChoice;

//

/*
When this style is present in Container, then tooltip does not work
&::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      border-radius: ${theme.shape.borderRadius.small}px;
      box-shadow: ${
        highlighted ? `0 0 0 2px ${theme.palette.border.D}` : "none"
      };
    }
  */
