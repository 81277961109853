import { IconProps } from "../types";

interface DandiProps extends IconProps {}

const Dandi = ({ size = 28 }: DandiProps) => {
  const width = (size / 28) * size;
  const height = (size / 25) * size;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27.5312 10.166V7.70862L23.5995 7.70862V10.166C23.5995 15.4756 19.2728 19.8024 13.77 19.8024C8.26727 19.8024 3.94056 15.4756 3.94056 10.166L3.94056 7.70862L0 7.70862L0 10.166C0 17.8365 6.29261 24.1291 13.77 24.1291C21.2386 24.1291 27.5312 17.8365 27.5312 10.166Z'
        fill='currentColor'
      />
      <path
        d='M21.4332 0.53125L17.1504 0.53125V4.85796L21.4332 4.85796V0.53125Z'
        fill='currentColor'
      />
      <path
        d='M10.632 0.53125L6.34912 0.53125L6.34912 4.85796L10.632 4.85796V0.53125Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Dandi;
