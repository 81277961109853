import { DefaultTheme } from "styled-components";

import { ColorName } from "../ui/theme/types";
import * as themes from "../ui/theme/versions";

export const themeBuilder = (name: string): DefaultTheme => {
  let theme: DefaultTheme = themes.DefaultLightTheme;
  Object.entries(themes).forEach(([key, t]) => {
    if (t.name === name) {
      theme = t;
    }
  });
  return theme;
};

export const switchBackground = (
  theme: DefaultTheme,
  colorName: ColorName
): DefaultTheme => {
  if (theme.type === "light") {
    theme.palette.bg = {
      ...theme.palette.bg,
      A: theme.colors[colorName][0],
      B: theme.colors[colorName][100],
      C: theme.colors[colorName][300],
      D: theme.colors[colorName][400],
      E: theme.colors[colorName][500],
      F: theme.colors[colorName][600],
      G: theme.colors[colorName][700],
      H: theme.colors[colorName][800],
      I: theme.colors[colorName][900],
      J: theme.colors[colorName][1000],
    };
  }

  if (theme.type === "dark") {
    theme.palette.bg = {
      ...theme.palette.bg,
      A: theme.colors[colorName][800],
      B: theme.colors[colorName][900],
      C: theme.colors[colorName][1000],
      D: theme.colors[colorName][700],
      E: theme.colors[colorName][600],
      F: theme.colors[colorName][500],
      G: theme.colors[colorName][400],
      H: theme.colors[colorName][300],
      I: theme.colors[colorName][200],
      J: theme.colors[colorName][100],
    };
  }

  return theme;
};
